import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { environment } from "../environments/environment";

const client = (() => {
  return axios.create({
    baseURL: environment.WebApiBaseUrl,
    responseType: "json",
  });
})();

export const setAuthToken = (token) => {
  if (token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete client.defaults.headers.common["Authorization"];
  }
};

export const queryRequest = async (options, store) => {
  const onSuccess = async (response) => {
    const {
      data: { payload },
    } = response;
    return payload;
  };

  const onError = function (error) {
    return Promise.reject(error.response);
  };
  // return client(options).then(onSuccess).catch(onError);
  return await trackPromise(client(options).then(onSuccess).catch(onError));
};
export const downloadRequest = async (options) => {
  const onSuccess = async (response) => response;

  const onError = function (error) {
    return Promise.reject(error.response);
  };
  // return client(options).then(onSuccess).catch(onError);
  return await trackPromise(client(options).then(onSuccess).catch(onError));
};

export default client;
