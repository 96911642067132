import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';


export const getDashboardData = async () => {
    try {
        const result = await trackPromise(axios.get('/dashboard'));
        const data = result.data;
        if (data.isSuccess) {
            return data.payload;
        }
    }
    catch (err) {
        return [];
    }
};


const DashboardService = {
    getDashboardData,
};

export default DashboardService; 