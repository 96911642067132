import { Route, Routes } from "react-router-dom";
import "./main.css";
import { createContext } from "react";

import LoginPage from "./features/auth/LoginPage";
import ForgotPasswordPage from "./features/auth/ForgotPasswordPage";
import ResetPasswordPage from "./features/auth/ResetPasswordPage";
import ProtectedRoute from "./routes/protected-route";
import Layout from "./layout/Layout";
import Dashboard from "./features/dashboard/Dashboard";
import DocumentManagement from "./features/document-management/DocumentManagement";
import ParentsManagement from "./features/parents-management/ParentsManagement";
import ChildrenManagement from "./features/children-management/ChildrenManagement";
import EmployManagement from "./features/employees/EmployeesManagment";
import TourRequestForm from "./features/tour-request-form/TourRequestForm";
import TourRequestManagement from "./features/tour-request-management/TourRequestManagement";
import NotFoundPage from "./features/not-found/NotFoundPage";
import FormTemplatesManagement from "./features/form-template-management/FormTemplatesManagement";
import DocumentSignRedirectPage from "./features/document-sign-redirect-page/DocumentSignRedirectPage";
import TourConfirmPage from "./features/tour-confirm-page/TourConfirmPage";
import RequestTourDates from "./features/request-tour-dates/RequestTourDates";
import { ListOfResources } from "./features/resources/ListOfResources";
import MyFolders from "./features/my-folders/MyFolders";

export const UserContext = createContext();

function App() {
  return (
    <Routes>
      <Route element={<Layout isPublic={true} />}>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="login" element={<LoginPage />} />
        <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route exact path="/reset-password" element={<ResetPasswordPage />} />
        <Route exact path="/notfound" element={<NotFoundPage />} />

        <Route
          exact
          path="/tour-request/create"
          element={<TourRequestForm />}
        />
        <Route
          exact
          path="/tour-request/:id/confirm"
          element={<TourConfirmPage />}
        />
        <Route
          exact
          path="/tour-request/:id/date-request"
          element={<RequestTourDates />}
        />

        <Route
          exact
          path="/doc/:id/:type"
          element={<DocumentSignRedirectPage />}
        />
      </Route>

      <Route element={<Layout />}>
        <Route
          path="dashboard"
          element={
            <ProtectedRoute
              allowedRoles={["Admin", "SuperAdmin", "Parent", "Employee"]}
            >
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="documents"
          element={
            <ProtectedRoute
              allowedRoles={["Admin", "SuperAdmin", "Parent", "Employee"]}
            >
              <DocumentManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="forms"
          element={
            <ProtectedRoute allowedRoles={["Admin", "SuperAdmin", "Employee"]}>
              <FormTemplatesManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="resources"
          element={
            <ProtectedRoute allowedRoles={["Admin", "SuperAdmin", "Employee","Parent"]}>
              <ListOfResources />
            </ProtectedRoute>
          }
        />
        <Route
          path="my-folders"
          element={
            <ProtectedRoute allowedRoles={["SuperAdmin", "Employee","Parent"]}>
              <MyFolders />
            </ProtectedRoute>
          }
        />

        <Route
          element={<ProtectedRoute allowedRoles={["Admin", "SuperAdmin"]} />}
        >
          <Route path="parents" element={<ParentsManagement />} />
          <Route path="children" element={<ChildrenManagement />} />
          <Route path="employees" element={<EmployManagement />} />
          <Route path="tour-requests" element={<TourRequestManagement />} />
          <Route path="tour-requests/:id" element={<TourRequestManagement />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
