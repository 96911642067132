import axios, { queryRequest } from "../config/API";
import { trackPromise } from "react-promise-tracker";

export const getAllParents = async () => {
  return queryRequest({
    url: `/Parents`,
    method: "GET",
  });
};
export const getParentById = async (id) => {
  return queryRequest({
    url: `/Parents/${id}`,
    method: "GET",
  });
};

export const createParent = async (parentData) => {
  try {
    const result = await trackPromise(axios.post(`/parents`, parentData));
    const data = result.data;
    return {
      result: data.payload,
      isSuccess: data.isSuccess,
      error: data.message,
    };
  } catch (err) {
    return { result: null, isSuccess: false, error: err.message };
  }
};
export const updateParent = async (id, parentData) => {
  try {
    const result = await trackPromise(
      axios.patch(`/parents/${id}`, parentData)
    );
    const data = result.data;
    return {
      result: data.payload,
      isSuccess: data.isSuccess,
      error: data.message,
    };
  } catch (err) {
    return { result: null, isSuccess: false, error: err.message };
  }
};

const ParentsService = {
  getAllParents,
  getParentById,
  createParent,
  updateParent,
};

export default ParentsService;
