import axios from "../config/API";
import { queryRequest } from "../config/API";
import { trackPromise } from "react-promise-tracker";

//#region CRUD
export const createTourRequest = async (requestData) => {
  try {
    const result = await trackPromise(axios.post(`/TourRequests`, requestData));
    const data = result.data;
    return {
      result: data.payload,
      isSuccess: data.isSuccess,
      error: data.message,
    };
  } catch (err) {
    return { result: null, isSuccess: false, error: err.title };
  }
};
export const getAllTourRequests = async () => {
  try {
    const result = await trackPromise(axios.get("/TourRequests"));
    const data = result.data;
    if (data.isSuccess) {
      return data.payload;
    }
  } catch (err) {
    return [];
  }
};
export const getTourRequestById = async (id) => {
  return queryRequest({
    url: `/TourRequests/${id}`,
    method: "GET",
  });
};
export const updateTourRequest = async (requestData) => {
  try {
    const result = await trackPromise(
      axios.patch(`/TourRequests/`, requestData)
    );
    const data = result.data;
    if (data.isSuccess) {
      //set success snackBar
      return data.payload;
    }
  } catch (err) {
    return null;
  }
};
//#endregion

export const createParentFromTourRequest = async (id) => {
  try {
    const result = await trackPromise(
      axios.post(`/TourRequests/${id}/createparent`)
    );
    const data = result.data;
    if (data.isSuccess) {
      return data.payload;
    }
  } catch (err) {
    return null;
  }
};
export const setTourRequestScheduleDates = async (id, dates) => {
  try {
    const result = await trackPromise(
      axios.patch(`/TourRequests/${id}/schedule`, dates)
    );
    const data = result.data;
    if (data.isSuccess) {
      //set success snackBar
      return data.payload;
    }
  } catch (err) {
    return null;
  }
};

export const getTourScheduleById = async (id) => {
  return queryRequest({
    url: `/TourRequests/${id}/schedule`,
    method: "GET",
  });
};

export const confirmTourSchedule = async (id, selectedSlot) => {
  return queryRequest({
    url: `/TourRequests/${id}/schedule/confirm?visitDate=${selectedSlot}`,
    method: "PATCH",
  });
};

export const requestTimeSlot = async (id) => {
  return queryRequest({
    url: `/TourRequests/${id}/schedule/request`,
    method: "PATCH",
  });
};

const TourRequestsService = {
  createTourRequest,
  getAllTourRequests,
  getTourRequestById,
  updateTourRequest,

  createParentFromTourRequest,
  setTourRequestScheduleDates,
  getTourScheduleById,
  confirmTourSchedule,
  requestTimeSlot,
};
export default TourRequestsService;
