import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TourRequestService from "../../services/tour-request-management.service";
import { TourRequestStatusEnum } from "../../enums/TourRequestStatus.enum";

const initialState = {
  tourRequests: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  selectedRequest: null,
  isModalOpen: false,
};

export const fetchAllTourRequests = createAsyncThunk(
  "tourRequests/fetchAll",
  async () => {
    const response = await TourRequestService.getAllTourRequests();
    return response;
  }
);

export const updateTourRequest = createAsyncThunk(
  "tourRequests/update",
  async (data) => {
    const response = await TourRequestService.updateTourRequest(data);
    return response;
  }
);

export const setTourRequestScheduleDates = createAsyncThunk(
  "tourRequests/setScheduleDates",
  async (data) => {
    const response = await TourRequestService.setTourRequestScheduleDates(
      data.id,
      data.dates
    );
    return response;
  }
);

export const sendEarliestAvailability = createAsyncThunk(
  "tourRequests/sendEarliestAvailability",
  async (data) => {
    const response = await TourRequestService.sendEarliestAvailability(
      data.id,
      data.availableMonth
    );
    return response;
  }
);
export const sendEnrolmentDocuments = createAsyncThunk(
  "tourRequests/sendEnrolmentDocuments",
  async (data) => {
    const response = await TourRequestService.createParentFromTourRequest(
      data.id
    );
    return response;
  }
);

export const tourRequestSlice = createSlice({
  name: "tourRequests",
  initialState,
  reducers: {
    clearSelectedTourRequest: (state) => {
      state.selectedRequest = null;
    },
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    selectTourRequest: (state, { payload }) => {
      state.selectedRequest = state.tourRequests.find(
        (tourRequest) => tourRequest.id === payload.id
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // Get All
      .addCase(fetchAllTourRequests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllTourRequests.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tourRequests = [...action?.payload];
      })
      .addCase(fetchAllTourRequests.rejected, (state, action) => {
        state.status = "failed";
        state.tourRequests = [];
        state.error = action?.error.message;
      })

      // Set Schedule Dates
      .addCase(setTourRequestScheduleDates.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(setTourRequestScheduleDates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tourRequests = [
          ...state.tourRequests.map((tr) =>
            tr.id === action?.payload.id ? action?.payload : tr
          ),
        ];
        state.selectedRequest = action?.payload;
      })
      .addCase(setTourRequestScheduleDates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error.message;
      })

      // Update Status
      .addCase(updateTourRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTourRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tourRequests = [
          ...state.tourRequests.map((tr) =>
            tr.id === action?.payload.id ? action?.payload : tr
          ),
        ];
        state.selectedRequest = action?.payload;
      })
      .addCase(updateTourRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error.message;
      })

      // Update Status
      .addCase(sendEnrolmentDocuments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendEnrolmentDocuments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tourRequests = [
          ...state.tourRequests.map((tr) =>
            tr.id === action?.payload.id
              ? { ...tr, status: TourRequestStatusEnum.EnrolmentInitiated }
              : tr
          ),
        ];
        state.selectedRequest = !!state.selectedRequest
          ? {
              ...state.selectedRequest,
              status: TourRequestStatusEnum.EnrolmentInitiated,
            }
          : null;
      })
      .addCase(sendEnrolmentDocuments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error.message;
      })

      // Send Earliest Availability
      .addCase(sendEarliestAvailability.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendEarliestAvailability.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tourRequests = [
          ...state.tourRequests.map((tr) =>
            tr.id === action?.payload.id ? action?.payload : tr
          ),
        ];
        state.selectedRequest = action?.payload;
      })
      .addCase(sendEarliestAvailability.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error.message;
      });
  },
});

export const {
  clearSelectedTourRequest,
  openModal,
  closeModal,
  selectTourRequest,
} = tourRequestSlice.actions;

export const getSelectedTourRequest = (state) =>
  state.tourRequests.selectedRequest;
export const getAllTourRequests = (state) => state.tourRequests.tourRequests;
export const getIsModalOpen = (state) => state.tourRequests.isModalOpen;
export const getTourRequestError = (state) => state.tourRequests.error;

export default tourRequestSlice.reducer;
