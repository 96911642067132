import { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { TemplateTypeEnum } from "../../enums/TemplateType.enum";
import { TemplateTypeMap } from "../../enumMaps/templateType.map";

const TemplateForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [checked, setChecked] = useState(props?.defaultValue?.isBulkSendEnable);
  const [type, setType] = useState(props?.defaultValue?.type);
  const onCheck = (event) => {
    setChecked(event.target.checked);
  };
  const onSelectType = (event) => {
    setType(event.target.value);
  };
  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(props.onSubmit)}
      ref={props.formRef}
      //   noValidate
    >
      <Grid p={2} container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            defaultValue={props.defaultValue?.name}
            fullWidth
            {...register(`name`, {
              required: "Template's name is required.",
            })}
            label={"Form Name*"}
            error={errors?.name !== undefined ? errors?.name : false}
            helperText={
              errors?.name !== undefined ? errors?.name?.message : ""
            }
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            value={type}
            {...register(`type`, {
              required: "Form type is required.",
            })}
            label="Type"
            error={errors?.type !== undefined ? errors?.type : false}
            helperText={errors?.type !== undefined ? errors?.type?.message : ""}
            onChange={onSelectType}
          >
            {Object.values(TemplateTypeEnum).map((value, index) => {
              return (
                <MenuItem value={value} key={index}>
                  {TemplateTypeMap.get(value).label}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        {!props.isEdit && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              {...register(`zohoTemplateId`, {
                required: "Template's zohoTemplateId is required.",
              })}
              label={"Zoho Template Id"}
              error={
                errors?.zohoTemplateId !== undefined
                  ? errors?.zohoTemplateId
                  : false
              }
              helperText={
                errors?.zohoTemplateId !== undefined
                  ? errors?.zohoTemplateId?.message
                  : ""
              }
            ></TextField>
          </Grid>
        )}
        <Grid item sm={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                // defaultChecked={props?.defaultValue?.isBulkSendEnable}
                inputProps={{ "aria-label": "controlled" }}
                checked={checked}
                onChange={onCheck}
              />
            }
            label="Enable Bulksend"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 36 } }}
            {...register(`isBulkSendEnable`)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
TemplateForm.propTypes = {
  formRef: PropTypes.any,
  defaultValue: PropTypes.any,
  isEdit: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  index: PropTypes.func,
};

export default forwardRef((props, ref) => (
  <TemplateForm {...props} forwardedRef={ref} />
));
