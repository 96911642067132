import { configureStore } from '@reduxjs/toolkit';
import childrenReducer from '../features/children-management/childrenSlice';
import documentsReducer
  from '../features/document-management/documentsSlice';
import EmployeesReducer from '../features/employees/EmployeeSlice';
import TemplatesReducer from '../features/form-template-management/FormTemplateSlice';
import TourRequestReducer from '../features/tour-request-management/TourRequestSlice';
import ParentsReduce from '../features/parents-management/ParentsSlice';
import layoutReducer from '../layout/layoutSlice';
import AuthSlice from '../features/auth/AuthSlice';


const reducer = {
  auth: AuthSlice,
  layout: layoutReducer,
  children: childrenReducer,
  documents: documentsReducer,
  employees: EmployeesReducer,
  templates: TemplatesReducer,
  tourRequests: TourRequestReducer,
  parents: ParentsReduce
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
});
export default store;