import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authService } from "../../services/auth.service";


const initialState = {
    loading: false,
    error: null,
    success: false,
    isLogged: false,
    authToken: localStorage.getItem('authToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || null
};

export const userLogin = createAsyncThunk(
    'auth/login',
    async ({ email, password }) => {
        const res = await authService.login(email, password);
        localStorage.setItem('authToken', res.token);
        localStorage.setItem('refreshToken', res.refreshToken);
        localStorage.setItem('userInfo', JSON.stringify(res.userInfo));
        return { ...res };
    }
);



const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.clear();

            state.loading = false;
            state.userInfo = null;
            state.authToken = null;
            state.refreshToken = null;
            state.error = null;
        },
        setCredentials: (state, { payload }) => {
            state.userInfo = payload;
        },
        // TODO once BE fixed
        updateTokens: (state, { payload }) => {
            state.authToken = payload.authToken;
            state.refreshToken = payload.refreshToken;
            localStorage.setItem('authToken', payload.token);
            localStorage.setItem('refreshToken', payload.refreshToken);
            localStorage.setItem('userInfo', JSON.stringify(payload.userInfo));
        }
    },
    extraReducers: {
        // login user
        [userLogin.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [userLogin.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.userInfo = payload.userInfo;
            state.authToken = payload.token;
        },
        [userLogin.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const { logout, setCredentials, updateTokens } = authSlice.actions;

//Selectors
export const getLoggedUser = (state) => state.auth?.userInfo;
export const getLoggedUserRoles = (state) => state.auth?.userInfo?.roles;
export const getAuthToken = (state) => state.auth?.authToken;
export const getRefreshToken = (state) => state.auth?.refreshToken;

export default authSlice.reducer;
