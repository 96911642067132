import React, { forwardRef, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ModalContent from "../../components/Modal/ModalContent";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedParent, updateParent } from "./ParentsSlice";
import { useForm } from "react-hook-form";

const EditParentModal = ({ modalCloseHandle, onSubmit }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const parent = useSelector(getSelectedParent);

  const formRef = useRef();

  useEffect(() => {
    if (parent) {
      setValue("firstName", parent.firstName);
      setValue("lastName", parent.lastName);
      setValue("email", parent.email);
      setValue("phoneNumber", parent.phoneNumber);
    }
  }, [parent, setValue]);

  const handleUpdateParent = (data) => {
    dispatch(updateParent({ id: parent.id, parentData: data }));
    if (onSubmit) onSubmit();

    modalCloseHandle();
  };

  const onClickCreateParent = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const content = (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(handleUpdateParent)}
      ref={formRef}
    >
      <Grid p={2} container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            {...register("firstName", {
              required: "Parent's first name is required.",
            })}
            label={"First Name*"}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            {...register("lastName", {
              required: "Parent's last name is required.",
            })}
            label={"Last Name*"}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            {...register("email", {
              required: "Parent's email is required.",
            })}
            label={"E-Mail*"}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            {...register("phoneNumber")}
            label={"Phone Number"}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
          />
        </Grid>
      </Grid>
    </Box>
  );

  const actions = (
    <React.Fragment>
      <Button onClick={onClickCreateParent} variant="contained">
        Update Parent
      </Button>
    </React.Fragment>
  );

  return (
    <ModalContent
      title={"Edit Parent"}
      content={content}
      action={actions}
      handleClose={modalCloseHandle}
    />
  );
};

EditParentModal.propTypes = {
  modalCloseHandle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default React.forwardRef((props, ref) => (
  <EditParentModal {...props} forwardedRef={ref} />
));
