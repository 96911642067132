import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';


export const login = async (email, password) => {
    const result = await trackPromise(axios.post('/auth/login', { email, password }));
    if (result.status === 200)
        return result.data;
};

export const getTokenRefresh = async (token, refreshToken) => {
    const result = await trackPromise(axios.put('/auth/refresh-token', { token, refreshToken }));
    return result;
};


async function forgotPassword(email) {
    try {
        const result = await trackPromise(axios.post(`/Auth/forgot-password`, { email }));
        return result.data;
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to request password reset." };
    }
}
async function resetPassword(data) {
    try {
        const result = await trackPromise(axios.post(`/Auth/reset-password`, { ...data }));
        return result.data;
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to reset password." };
    }
}

async function ValidateToken() {
    try {
        const result = await trackPromise(axios.get(`/Auth/isValidToken`));
        if (result.data.isSuccess) {
            return result.data;
        }
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to validate token" };
    }
}

async function AuthDropBox() {
    try {
        const result = await trackPromise(axios.get(`/dbx/Auth`));
        if (result.data.isSuccess) {
            return result.data;
        }
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to validate token" };
    }
}

export const authService = {
    login,
    getTokenRefresh,
    forgotPassword,
    ValidateToken,
    AuthDropBox,
    resetPassword
};