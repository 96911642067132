import React, { useEffect, useState } from "react";
import ResourcesService from "../../services/resources.service";
import { Box, Button, ButtonGroup } from "@mui/material";
import DataTable from "../../components/data-table/DataTable";

const columns = [
  {
    name: "name",
    disablePadding: false,
    label: "Name",
    align: "left",
  },
  {
    name: "action",
    disablePadding: false,
    label: "Action",
    align: "right",
  },
];

export const ListOfResources = () => {
  const [resources, setResources] = useState([]);

  useEffect(() => {
    ResourcesService.getAllResources()
      .then((data) => {
        if (!!data) {
          setResources(data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onClickDownload = (id) => {
    ResourcesService.downloadResource(id);
  };

  const actionBtn = (id,name) => {
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        <Button
          variant="text"
          id={"view" + id}
          onClick={() => ResourcesService.downloadResource(id, name)}
        >
          Download
        </Button>
      </ButtonGroup>
    );
  };

  const createRowData = (resources) => {
    return resources.map((r) => {
      return { id: r.id, name: r.name, action: actionBtn(r.id, r.name) };
    });
  };

  return (
    <React.Fragment>
      <Box
        className="info-section"
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <DataTable
          isDense={true}
          rows={createRowData(resources)}
          columns={columns}
          sortBy={"name"}
        />
      </Box>
    </React.Fragment>
  );
};
