import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DocumentService from '../../services/document-management-service';

const initialState = {
    clientDocumentList: [],
    employeeDocumentList: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

export const fetchDocuments = createAsyncThunk('documents/fetchAll', async () => {
    const response = await DocumentService.getAllDocuments();
    return response;
});
export const updateDocumentList = createAsyncThunk('documents/updateList', async () => {
    const response = await DocumentService.updateDocumentStatus();
    return response;
});
export const fetchDocument = createAsyncThunk('document/fetchById', async (id) => {
    const response = await DocumentService.getDocumentById(id);
    return response;
});


export const documentsSlice = createSlice({
    name: 'Documents',
    initialState,
    reducers: {
        clearSelectedChild: (state) => {
            state.selectedChild = null;
        },
    },
    extraReducers(builder) {
        builder
            // Fetch all
            .addCase(fetchDocuments.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDocuments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.clientDocumentList = [...action?.payload?.filter(d => d.isClientDocument)];
                state.employeeDocumentList = [...action?.payload?.filter(d => !d.isClientDocument)];
            })
            .addCase(fetchDocuments.rejected, (state, action) => {
                state.status = 'failed';
                state.clientDocumentList = [];
                state.employeeDocumentList = [];
                state.error = action.error.message;
            })
            // update list
            .addCase(updateDocumentList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateDocumentList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.clientDocumentList = [...action?.payload?.filter(d => d.isClientDocument)];
                state.employeeDocumentList = [...action?.payload?.filter(d => !d.isClientDocument)];
            })
            .addCase(updateDocumentList.rejected, (state, action) => {
                state.status = 'failed';
                state.clientDocumentList = [];
                state.employeeDocumentList = [];
                state.error = action.error.message;
            });
    }
});

// Action creators are generated for each case reducer function
export const {
    clearSelectedDocument,
} = documentsSlice.actions;

//Selectors
export const getAllClientDocuments = (state) => state.documents?.clientDocumentList;
export const getAllEmployeeDocuments = (state) => state.documents?.employeeDocumentList;

export default documentsSlice.reducer;