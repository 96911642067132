import React, { useEffect, useState } from "react";
import DataTable from "../../components/data-table/DataTable";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import useTitle from "../../hooks/useTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEmployee,
  clearSelectedEmployee,
  deleteEmployeeById,
  fetchEmployees,
  getAllEmployees,
  openModal,
  closeModal,
  getEmployeeModalStatus,
} from "./EmployeeSlice";
import CreateEmployeeModal from "./CreateEmployeeModal";
import EditEmployeeModal from "./EditEmployeeModal";

const columns = [
  {
    name: "employeeName",
    disablePadding: false,
    label: "Employee Name",
    align: "left",
  },
  {
    name: "email",
    disablePadding: false,
    label: "E-mail",
    align: "left",
  },
  {
    name: "phoneNumber",
    disablePadding: false,
    label: "Phone Number",
    align: "left",
  },
  {
    name: "action",
    disablePadding: false,
    label: "Action",
    align: "left",
  },
];

const EmployManagement = (props) => {
  useTitle("Employee Management");
  const dispatch = useDispatch();

  // const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("none");

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  const employees = useSelector(getAllEmployees);
  let isModalOpen = useSelector(getEmployeeModalStatus);

  const openEmployeeCreate = () => {
    dispatch(openModal());
    setModalType("create");
  };

  const openEmployeeEdit = (id) => {
    dispatch(selectEmployee({ id }));
    dispatch(openModal());
    setModalType("edit");
  };

  const openEmployeeView = (id) => {
    dispatch(openModal());
    dispatch(selectEmployee({ id }));
    setModalType("view");
  };

  const deleteEmployee = (id) => {
    setModalType("delete");
    dispatch(openModal());
    dispatch(deleteEmployeeById(id));
  };

  const handleModalClose = () => {
    dispatch(clearSelectedEmployee());
    dispatch(closeModal());
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const actionBtn = (id) => {
    return (
      <ButtonGroup>
        {/* <Button
          variant='text'
          id={"view-" + id}
          onClick={() => openEmployeeView(id)}>
          View more
        </Button> */}
        <Button
          variant='text'
          size='small'
          id={"edit-" + id}
          onClick={() => openEmployeeEdit(id)}>
          Edit
        </Button>
        <Button
          size='small'
          color='error'
          variant='text'
          id={"delete-" + id}
          onClick={() => deleteEmployee(id)}>
          Remove
        </Button>
      </ButtonGroup>
    );
  };

  const createRowData = (data) => {
    if (Array.isArray(data))
      return data.map((r) => ({
        id: r.id,
        employeeName: r.firstName + " " + r.lastName,
        email: r.email,
        phoneNumber: r.phoneNumber,
        action: actionBtn(r.id),
      }));
    else return [];
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}>
        <div>
          <Button
            variant='outlined'
            onClick={openEmployeeCreate}
            startIcon={<Add />}>
            Add Employee
          </Button>
        </div>
        <Box className='info-section'>
          <DataTable
            isDense={true}
            rows={createRowData(employees)}
            columns={columns}
            sortBy={"employeeName"}
          />
        </Box>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        sx={{ minHeight: "50vh" }}
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby='employee-info'>
        {modalType === "create" && (
          <CreateEmployeeModal modalCloseHandle={handleModalClose} />
        )}
        {modalType === "edit" && (
          <EditEmployeeModal modalCloseHandle={handleModalClose} />
        )}
        {/* {modalType==='view' && (
          <EmployeeInfoModal
            modalCloseHandle={handleModalClose}
            id={selectedEmployeeId}
          />
        )} */}
      </Dialog>
    </React.Fragment>
  );
};

// EmployManagement.propTypes = {};

export default EmployManagement;
