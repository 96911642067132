import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLoggedUser } from "../features/auth/AuthSlice";

const hasPermission = (allowedRoles, currentUser) => {
  if (allowedRoles) {
    if (allowedRoles === "isLogged") {
      return currentUser != null;
    }
    if (currentUser) {
      if (currentUser.roles) {
        return currentUser.roles.some((r) => allowedRoles.includes(r));
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const ProtectedRoute = ({
  children,
  layout: Layout,
  allowedRoles = ["isLogged"],
  redirectPath = "/login",
  path,
  ...rest
}) => {
  const location = useLocation();
  const loggedUser = useSelector(getLoggedUser);

  return hasPermission(allowedRoles, loggedUser) ? (
    children ? (
      children
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate
      to='/'
      replace
      state={{ from: location }}
    />
  );
};
export default ProtectedRoute;
