import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";

const EmployeeForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Box
      component='form'
      autoComplete='off'
      onSubmit={handleSubmit(props.onSubmit)}
      ref={props.formRef}
      //   noValidate
    >
      <Grid
        p={2}
        container
        spacing={2}>
        <Grid
          item
          xs={12}
          md={6}>
          <TextField
            fullWidth
            defaultValue={props.defaultValue?.firstName}
            {...register(`firstName`, {
              required: "Employee first name is required.",
            })}
            label={"First Name*"}
            error={errors?.firstName !== undefined ? errors?.firstName : false}
            helperText={
              errors?.firstName !== undefined ? errors?.firstName?.message : ""
            }></TextField>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <TextField
            fullWidth
            defaultValue={props.defaultValue?.lastName}
            {...register(`lastName`, {
              required: "Employee last name is required.",
            })}
            label={"Last Name*"}
            error={errors?.lastName !== undefined ? errors?.lastName : false}
            helperText={
              errors?.lastName !== undefined ? errors?.lastName?.message : ""
            }></TextField>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <TextField
            fullWidth
            defaultValue={props.defaultValue?.email}
            {...register(`email`, {
              required: "Employee email is required.",
            })}
            label={"E-Mail*"}
            error={errors?.email !== undefined ? errors?.email : false}
            helperText={
              errors?.email !== undefined ? errors?.email?.message : ""
            }></TextField>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <TextField
            fullWidth
            defaultValue={props.defaultValue?.phoneNumber}
            {...register(`phoneNumber`, {
              required: "Employee phone number is required.",
              maxLength: {
                value: 17,
                message:
                  "Phone number must be 10 digits as (xxx) xxx-xxxx without brackets and spaces.",
              },
              // onChange: (e) =>{
              //   e.target.value = autoFormatPhoneNumber(e.target.value);
              // },
            })}
            label={"Phone Number*"}
            error={
              errors?.phoneNumber !== undefined ? errors?.phoneNumber : false
            }
            helperText={
              errors?.phoneNumber !== undefined
                ? errors?.phoneNumber?.message
                : ""
            }></TextField>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <TextField
            select
            fullWidth
            defaultValue={props.defaultValue?.employeeCategory}
            {...register(`employeeCategory`, {
              required: "Employee category is required.",
            })}
            label={"Employee category*"}
            error={
              errors?.employeeCategory !== undefined
                ? errors?.employeeCategory
                : false
            }
            helperText={
              errors?.employeeCategory !== undefined
                ? errors?.employeeCategory?.message
                : ""
            }>
            <MenuItem value={0}>Manager</MenuItem>
            <MenuItem value={1}>Lead Teacher</MenuItem>
            <MenuItem value={2}>Assistant Teacher</MenuItem>
            <MenuItem value={3}>Teacher Aide</MenuItem>
            <MenuItem value={4}>Kitchen Staff</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );
};
EmployeeForm.propTypes = {
  formRef: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  index: PropTypes.func,
  defaultValue: PropTypes.any,
};

export default forwardRef((props, ref) => (
  <EmployeeForm
    {...props}
    forwardedRef={ref}
  />
));
// eslint-disable-next-line no-unused-vars
function autoFormatPhoneNumber(phoneNumberString) {
  try {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{0,3})?(\d{0,3})?(\d{0,4})?$/);
    var intlCode = match[1] ? "+1 " : "";
    return [
      intlCode,
      match[2] ? "(" : "",
      match[2],
      match[3] ? ") " : "",
      match[3],
      match[4] ? "-" : "",
      match[4],
    ].join("");
  } catch (err) {
    return "";
  }
}
