import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkSend,
  getApiStatus,
  getSelectedTemplate,
  sendToSign,
} from "./FormTemplateSlice";
import ModalContent from "../../components/Modal/ModalContent";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  fetchChildParentSummeryList,
  getChildParentsSummeryList,
} from "../children-management/childrenSlice";
import { fetchEmployees, getAllEmployees } from "../employees/EmployeeSlice";
import { getLoggedUser } from "../auth/AuthSlice";
import { RecipientCategoryEnum } from "../../enums/RecipientCategory.enum";
import { TemplateTypeEnum } from "../../enums/TemplateType.enum";
import { BulkSendCategory } from "../../enums/BulkSendCategory.enum";

const SendToSignModal = (props) => {
  const formRef = React.useRef(null);
  const dispatch = useDispatch();
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [recipients, setRecipients] = useState([]);

  const template = useSelector(getSelectedTemplate);

  const loggedUser = useSelector(getLoggedUser);
  const apiStatus = useSelector(getApiStatus);

  let childrenList = useSelector(getChildParentsSummeryList);
  useEffect(() => {
    if (
      template?.type === TemplateTypeEnum.Initial_Enrolment ||
      template?.type === TemplateTypeEnum.General_Student
    )
      if (!childrenList || childrenList?.length === 0)
        dispatch(fetchChildParentSummeryList());
  }, [childrenList, dispatch, template?.type]);

  let employeeList = useSelector(getAllEmployees);
  useEffect(() => {
    if (
      template?.type === TemplateTypeEnum.General_Employee ||
      template?.type === TemplateTypeEnum.Initial_Hiring
    ) {
      if (!employeeList || employeeList?.length === 0)
        dispatch(fetchEmployees());
    }
  }, [employeeList, dispatch, template?.type]);

  useEffect(() => {
    if (template?.recipients[0]?.category === RecipientCategoryEnum.MANAGER) {
      setRecipients(
        template.recipients.map((r, i) => ({
          ...r,
          name:
            i === 0 ? `${loggedUser.firstName} ${loggedUser.lastName}` : r.name,
          email: i === 0 ? loggedUser?.email : r.email,
        }))
      );
    } else if (
      template?.recipients[0]?.category === RecipientCategoryEnum.EMPLOYEE &&
      template?.type === TemplateTypeEnum.Employee_Request_Forms
    ) {
      setRecipients(
        template.recipients.map((r, i) => ({
          ...r,
          name:
            i === 0 ? `${loggedUser.firstName} ${loggedUser.lastName}` : r.name,
          email: i === 0 ? loggedUser?.email : r.email,
        }))
      );
    }
  }, [template, loggedUser]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onClickSend = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmit = async (formData) => {
    dispatch(
      sendToSign({
        templateId: template.id,
        requestData: {
          childId: selectedChild?.id,
          employeeId: selectedEmployee?.id,
          recipients: recipients,
        },
      })
    );
  };

  const onCLickBulkSend = (category) => {
    dispatch(
      bulkSend({
        templateId: template.id,
        category: category,
      })
    );
  };

  const onSelectChild = (value) => {
    setSelectedChild(value);
    setRecipients(
      template.recipients.map((r) => ({
        ...r,
        name:
          r.category === RecipientCategoryEnum.PARENT
            ? value?.parentFullName
            : r.name,
        email:
          r.category === RecipientCategoryEnum.PARENT
            ? value?.parentEmail
            : r.email,
      }))
    );
  };

  const onSelectEmployee = (value) => {
    setSelectedEmployee(value);
    setRecipients(
      template.recipients.map((r) => ({
        ...r,
        name:
          r.category === RecipientCategoryEnum.EMPLOYEE
            ? `${value.firstName} ${value.lastName}`
            : r.name,
        email:
          r.category === RecipientCategoryEnum.EMPLOYEE
            ? value?.email
            : r.email,
      }))
    );
  };

  const onInputNote = (recipientId, note) => {
    setRecipients(
      recipients.map((r) => ({
        ...r,
        private_notes: r.id === recipientId ? note : r.private_notes,
      }))
    );
  };

  const generateRecipients = (recipients) => {
    if (!recipients || recipients?.length === 0)
      recipients = template?.recipients;
    return recipients?.map((r) => {
      let placeholder;
      if (r.email)
        placeholder = {
          name: r.name,
          email: r.email,
        };
      else if (!!selectedChild)
        placeholder = {
          name: selectedChild.parentFullName,
          email: selectedChild.parentEmail,
        };
      else if (!!selectedEmployee)
        placeholder = {
          name: selectedEmployee?.user?.firstName,
          email: selectedEmployee?.user?.email,
        };

      let info = !!placeholder ? (
        <Box display={"flex"}>
          <Typography variant="body1" gutterBottom>
            {placeholder.name}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ margin: "0 5px", borderColor: "#2c2c2c52" }}
          ></Divider>
          <Typography variant="body1" gutterBottom>
            {placeholder.email}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography variant="body1" gutterBottom>
            Placeholder [{template?.type < 2000 ? "Parent" : "Employee"}]
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            (Select a recipient from above dropdown)
          </Typography>
        </Box>
      );
      return (
        <Grid
          sx={{ alignItems: "center", borderBottom: "dotted 1px #ccc" }}
          p={2}
          container
          spacing={2}
          key={r.id}
        >
          <Grid item xs={12} md={6} display={"flex"}>
            <Typography variant="body1" gutterBottom pr={0.5}>
              {r.signing_order}.
            </Typography>
            {info}
          </Grid>
          {/* <Grid
            item
            xs={12}
            md={6}>
            <TextField
              fullWidth
              label='Optional note to recipient'
              onInput={(e) => onInputNote(r.id, e.target.value)}
            />
          </Grid> */}
        </Grid>
      );
    });
  };
  const actions = (
    <React.Fragment>
      <Button
        onClick={onClickSend}
        variant="contained"
        disabled={apiStatus === "loading"}
      >
        {template?.recipients[0]?.category === RecipientCategoryEnum.MANAGER ||
        (template?.recipients[0]?.category === RecipientCategoryEnum.EMPLOYEE &&
          template?.type === TemplateTypeEnum.Employee_Request_Forms)
          ? "Initiate"
          : "Send document to sign"}
      </Button>
    </React.Fragment>
  );

  const content = (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit((formData) => onSubmit(formData))}
      ref={formRef}
    >
      <Grid p={2} container spacing={2}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}
        >
          {(template?.type === TemplateTypeEnum.Initial_Enrolment ||
            template?.type === TemplateTypeEnum.General_Student) && (
            <Autocomplete
              fullWidth
              options={childrenList}
              getOptionLabel={(option) => option.fullName}
              onChange={(event, value) => onSelectChild(value)}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.fullName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...register(`child`, {
                    required: "Select a child to send the document.",
                  })}
                  {...params}
                  label="Select a Child"
                  error={errors?.child !== undefined ? errors?.child : false}
                  helperText={
                    errors?.child !== undefined ? errors?.child?.message : ""
                  }
                />
              )}
            />
          )}
          {(template?.type === TemplateTypeEnum.General_Employee ||
            template?.type === TemplateTypeEnum.Initial_Hiring) && (
            <Autocomplete
              fullWidth
              options={employeeList}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              onChange={(event, value) => onSelectEmployee(value)}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.firstName} {option.lastName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...register(`employee`, {
                    required: "Select an employee to send the document.",
                  })}
                  {...params}
                  label="Select an Employee"
                  error={
                    errors?.employee !== undefined ? errors?.employee : false
                  }
                  helperText={
                    errors?.employee !== undefined
                      ? errors?.employee?.message
                      : ""
                  }
                />
              )}
            />
          )}
          {template?.isBulkSendEnable && actions}
        </Grid>

        {(template?.isBulkSendEnable && (
          <>
            <Grid item xs={12} md={2}>
              <Divider variant="middle" orientation="vertical">
                <Chip label="OR" />
              </Divider>
            </Grid>
            <Grid item xs={12} md={5} margin={"auto"}>
              {(template?.type === TemplateTypeEnum.General_Employee ||
                template?.type === TemplateTypeEnum.Initial_Hiring) && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() =>
                      onCLickBulkSend(BulkSendCategory.ALL_EMPLOYEES)
                    }
                    disabled={apiStatus === "loading"}
                  >
                    Send to ALL &nbsp; <strong>Employees</strong>
                  </Button>
                </Box>
              )}
              {(template?.type === TemplateTypeEnum.Initial_Enrolment ||
                template?.type === TemplateTypeEnum.General_Student) && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    variant="outlined"
                    disabled={apiStatus === "loading"}
                    onClick={() => onCLickBulkSend(BulkSendCategory.INFANTS)}
                  >
                    Send to ALL &nbsp; <strong>Infants</strong>
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={apiStatus === "loading"}
                    onClick={() => onCLickBulkSend(BulkSendCategory.TODDLER)}
                  >
                    Send to ALL &nbsp; <strong>Toddlers</strong>
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={apiStatus === "loading"}
                    onClick={() =>
                      onCLickBulkSend(BulkSendCategory.TRANSITION_ROOM)
                    }
                  >
                    Send to ALL &nbsp; <strong>Transition</strong>
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={apiStatus === "loading"}
                    onClick={() => onCLickBulkSend(BulkSendCategory.PRESCHOOL)}
                  >
                    Send to ALL &nbsp; <strong>Preschool</strong>
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={apiStatus === "loading"}
                    onClick={() =>
                      onCLickBulkSend(BulkSendCategory.ALL_PARENTS)
                    }
                  >
                    Send to ALL &nbsp; <strong>Parents</strong>
                  </Button>
                </Box>
              )}
            </Grid>
          </>
        )) || (
          <>
            <Grid item xs={12} md={2}>
              <Divider variant="middle" orientation="vertical">
                And
              </Divider>
            </Grid>
            <Grid item xs={12} md={4} margin={"auto"}>
              {actions}
            </Grid>
          </>
        )}
      </Grid>
      <Divider variant="middle" textAlign="left">
        <Chip label="Recipients" />
      </Divider>
      <Grid container>{generateRecipients(recipients)}</Grid>
    </Box>
  );

  return (
    <ModalContent
      title={`${template?.name}`}
      content={content}
      // action={actions}
      handleClose={props.modalCloseHandle}
    />
  );
};

SendToSignModal.propTypes = {
  modalCloseHandle: PropTypes.func.isRequired,
};

export default SendToSignModal;
