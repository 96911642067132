import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import { DocumentStatusEnum } from "../../enums/DocumentStatus.enum";
import { DocumentStatusMap } from "../../enumMaps/DocumentStatus.map";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statuses = [
  { label: "Pending", value: DocumentStatusEnum.Pending },
  { label: "Completed", value: DocumentStatusEnum.Completed },
  { label: "Expired", value: DocumentStatusEnum.Expired },
];

function getStyles(name, selectedStatus, theme) {
  return {
    fontWeight:
      selectedStatus.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const StatusSelector = ({ selectedStatus, onSelectStatus, theme }) => {
  return (
    <FormControl>
      <InputLabel id="status-dd-label">Status Filter</InputLabel>
      <Select
        sx={{ minWidth: 300 }}
        labelId="status-dd-label"
        id="status-dd"
        multiple
        value={selectedStatus}
        onChange={onSelectStatus}
        input={
          <OutlinedInput
            id="select-multiple-status"
            label="Status Filter"
            size="small"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
              size="small"
                key={value}
                label={DocumentStatusMap.get(value)?.label}
                color={DocumentStatusMap.get(value)?.color}
                variant={DocumentStatusMap.get(value)?.variant}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {statuses.map((status) => (
          <MenuItem
            key={status.value}
            value={status.value}
            style={getStyles(status, selectedStatus, theme)}
          >
            {status.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusSelector;
