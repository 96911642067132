import React, { useEffect } from "react";
import "./sidebar.scss";
import logo from "../../assets/images/logo.svg";
import SidebarItem from "./sidebar-item";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ChildCareRoundedIcon from "@mui/icons-material/ChildCareRounded";
import EscalatorWarningRoundedIcon from "@mui/icons-material/EscalatorWarningRounded";
import TourRoundedIcon from "@mui/icons-material/TourRounded";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { getLoggedUserRoles, logout } from "../../features/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import InventoryIcon from "@mui/icons-material/Inventory";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { closeSideBar, getSideBarStatus, openSideBar } from "../layoutSlice";
import { useLocation } from "react-router-dom";
import { IconSignature } from "@tabler/icons";
import PaymentIcon from "@mui/icons-material/Payment";
import { orange } from "@mui/material/colors";

const Sidebar = () => {
  const dispatch = useDispatch();
  // const user = useSelector(getLoggedUser);
  const currentUserRoles = useSelector(getLoggedUserRoles);
  const isSideBarOpen = useSelector(getSideBarStatus);
  const [openLogoutDialog, setOpenLogoutDialog] = React.useState(false);
  const [sideBarPosition, setSideBarPosition] = React.useState("relative");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenLogoutDialog(false);
  };
  useEffect(() => {
    if (fullScreen) {
      setSideBarPosition("absolute");
      dispatch(closeSideBar());
    } else {
      setSideBarPosition("relative");
      dispatch(openSideBar());
    }
  }, [dispatch, fullScreen]);

  const location = useLocation();
  const { pathname } = location;

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700],
    },
  }));
  const openPaymentGateway = () => {
    window
      .open("https://www.gobillandpay.com/radiantmontessori", "_blank")
      .focus();
  };

  return (
    <React.Fragment>
      {isSideBarOpen && (
        <Box
          className="sidebar-container"
          sx={{
            position: sideBarPosition,
          }}
        >
          <div className="logo-section">
            <img src={logo} alt="radian-logo" className="logo"></img>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <h2>Radiant</h2> */}
              <h1>
                <em>e</em>-Docs
              </h1>
            </div>
          </div>
          <div className="menu-items">
            <SidebarItem
              title="Dashboard"
              route="/dashboard"
              isFullScreen={fullScreen}
              isSelected={pathname === "/dashboard"}
              icon={<DashboardRoundedIcon />}
            ></SidebarItem>
            <SidebarItem
              title="e-docs"
              route="/documents"
              isFullScreen={fullScreen}
              isSelected={pathname === "/documents"}
              icon={<IconSignature />}
            ></SidebarItem>

            {
              // currentUserRoles?.includes("SuperAdmin") ||
              (currentUserRoles?.includes("Parent") ||
                currentUserRoles?.includes("Employee")) && (
                <SidebarItem
                  title="My Folders"
                  route="/my-folders"
                  isFullScreen={fullScreen}
                  isSelected={pathname === "/my-folders"}
                  icon={<FolderSharedIcon />}
                ></SidebarItem>
              )
            }

            {(currentUserRoles?.includes("SuperAdmin") ||
              currentUserRoles?.includes("Admin") ||
              currentUserRoles?.includes("Employee")) && (
              <SidebarItem
                title="Forms"
                route="/forms"
                isFullScreen={fullScreen}
                isSelected={pathname === "/forms"}
                icon={<NoteAltOutlinedIcon />}
              ></SidebarItem>
            )}
            {(currentUserRoles?.includes("SuperAdmin") ||
              currentUserRoles?.includes("Admin")) && (
              <SidebarItem
                title="Parents"
                route="/parents"
                isFullScreen={fullScreen}
                isSelected={pathname === "/parents"}
                icon={<EscalatorWarningRoundedIcon />}
              ></SidebarItem>
            )}
            {(currentUserRoles?.includes("SuperAdmin") ||
              currentUserRoles?.includes("Admin")) && (
              <SidebarItem
                title="Children"
                route="/children"
                isFullScreen={fullScreen}
                isSelected={pathname === "/children"}
                icon={<ChildCareRoundedIcon />}
              ></SidebarItem>
            )}
            {(currentUserRoles?.includes("SuperAdmin") ||
              currentUserRoles?.includes("Admin")) && (
              <SidebarItem
                title="Employees"
                route="/employees"
                isFullScreen={fullScreen}
                isSelected={pathname === "/employees"}
                icon={<SupervisorAccountIcon />}
              ></SidebarItem>
            )}
            {(currentUserRoles?.includes("SuperAdmin") ||
              currentUserRoles?.includes("Admin")) && (
              <SidebarItem
                title="Tour Requests"
                route="/tour-requests"
                isFullScreen={fullScreen}
                isSelected={pathname === "/tour-requests"}
                icon={<TourRoundedIcon />}
              ></SidebarItem>
            )}
            {(currentUserRoles?.includes("SuperAdmin") ||
              currentUserRoles?.includes("Parent") ||
              currentUserRoles?.includes("Employee")) && (
              <SidebarItem
                title="Resources"
                route="/resources"
                isFullScreen={fullScreen}
                isSelected={pathname === "/resources"}
                icon={<InventoryIcon />}
              ></SidebarItem>
            )}
            {currentUserRoles?.includes("Parent") && (
              <ColorButton
                sx={{
                  // width: "max-content",
                  margin: "0.5rem",
                  borderRadius: "0.5rem",
                  width: "13rem",
                  justifyContent: "start",
                  padding: "0.5rem 1rem",
                }}
                variant="contained"
                onClick={openPaymentGateway}
                startIcon={<PaymentIcon />}
              >
                Pay my Invoice
              </ColorButton>
            )}

            {/* <SidebarItem
              title='Profile'
              route='/profile'
              isFullScreen={fullScreen}
              isSelected={ pathname ==='/profile'}
              icon={<ManageAccountsIcon />}></SidebarItem> */}
          </div>
          <div className="settings-section">
            <div sx={{ borderRight: "1px solid" }}>
              <Tooltip title="Hide Side Menu">
                <IconButton
                  aria-label="sidebar-handle"
                  onClick={() => dispatch(closeSideBar())}
                >
                  <MenuOpenIcon />
                </IconButton>
              </Tooltip>
            </div>
            {/* <Divider orientation='vertical' /> */}
            <div>
              {/* <IconButton aria-label='notification'>
            <NotificationsRoundedIcon />
          </IconButton> */}
              {/* <Tooltip title='Profile Settings'>
                <IconButton aria-label='profile'>
                  <ManageAccountsIcon />
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Log out">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "inherit",
                    gap: "0.5rem",
                    boxShadow: "none",
                    color: "rgba(0, 0, 0, 0.54)",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#0000000a",
                      boxShadow: "none",
                    },
                  }}
                  color="secondary"
                  // color='white'
                  aria-label="logout"
                  onClick={() => setOpenLogoutDialog(true)}
                >
                  Log out
                  <MeetingRoomIcon />
                </Button>
              </Tooltip>
            </div>
          </div>
        </Box>
      )}
      {!isSideBarOpen && (
        <div className="compact-menu-button">
          <Tooltip title="Show Side Menu">
            <IconButton
              aria-label="sidebar-handle"
              onClick={() => dispatch(openSideBar())}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}

      <Dialog
        open={openLogoutDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>Logging Out?</DialogTitle>
        <DialogContent>
          Are you sure you want to log out from{" "}
          <strong>
            Radiant <i>e</i>-Docs
          </strong>
          ?
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            // color='error'
            variant="contained"
            onClick={() => dispatch(logout())}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Sidebar;
