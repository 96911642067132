import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./document-management.scss";
import ClientsDocuments from "./ClientsDocuments";
import EmployeesDocuments from "./EmployeesDocuments";
import { fetchDocuments } from "./documentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedUserRoles } from "../auth/AuthSlice";
import { useSearchParams } from "react-router-dom";
import { DocumentStatusEnum } from "../../enums/DocumentStatus.enum";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `document-tab-${index}`,
    "aria-controls": `document-tabpanel-${index}`,
  };
}

const DocumentManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let status = 0;
  switch (searchParams.get("status")) {
    case "pending":
      status = DocumentStatusEnum.Pending;
      break;
    case "completed":
      status = DocumentStatusEnum.Completed;
      break;
    case "expired":
      status = DocumentStatusEnum.Expired;
      break;
    case "rejected":
      status = DocumentStatusEnum.Rejected;
      break;
    default:
      status = 0;
  }

  let currentUserRoles = useSelector(getLoggedUserRoles);
  const [selectedTab, setSelectedTab] = useState(
    currentUserRoles.includes("Employee") ? 1 : 0
  );
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          centered>
          {(currentUserRoles.includes("SuperAdmin") ||
            currentUserRoles.includes("Admin") ||
            currentUserRoles.includes("Parent")) && (
            <Tab
              value={0}
              label={
                (currentUserRoles.includes("Parent") ? "Child's" : "Student") +
                " Documents"
              }
              {...a11yProps(0)}
            />
          )}
          {(currentUserRoles.includes("SuperAdmin") ||
            currentUserRoles.includes("Admin") ||
            currentUserRoles.includes("Employee")) && (
            <Tab
              value={1}
              label={
                (currentUserRoles.includes("Employee") ? "My" : "Employee") +
                " Documents"
              }
              {...a11yProps(1)}
            />
          )}
        </Tabs>
      </Box>
      <TabPanel
        className='info-section'
        value={selectedTab}
        index={0}>
        <ClientsDocuments status={status} />
      </TabPanel>
      <TabPanel
        className='info-section'
        value={selectedTab}
        index={1}>
        <EmployeesDocuments status={status} />
      </TabPanel>
    </Box>
  );
};

export default DocumentManagement;
