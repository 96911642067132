import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import "./dashboard.scss";
import { useSelector } from "react-redux";
import { getLoggedUser } from "../auth/AuthSlice";
import CountCards from "./CountCards";
import DashboardService from "../../services/dashboard.service";
import { Navigate, useNavigate } from "react-router-dom";

const authDropbox = () => {
  window
    .open(process.env.REACT_APP_PROD_WEB_API + "/api/v1/dbx/Auth", "_blank")
    .focus();
};

const greeting = () => {
  let greeting = "Hi";
  let hour = new Date().getHours();

  if (hour >= 5 && hour < 12) greeting = "Good Morning";
  else if (hour >= 12 && hour < 18) greeting = "Good Afternoon";
  else greeting = "Good Evening";
  return greeting;
};

const Dashboard = () => {
  const currentUser = useSelector(getLoggedUser);
  const role = currentUser?.roles[0];
  const [tourRequestCount, setTourRequestCount] = useState({
    New: 0,
    Scheduled: 0,
    Visited: 0,
    Joined: 0,
    Canceled: 0,
  });
  const [documentCount, setDocumentCount] = useState({
    Pending: 0,
    Completed: 0,
    Expired: 0,
    Rejected: 0,
  });

  useEffect(() => {
    DashboardService.getDashboardData()
      .then((data) => {
        if (!!data) {
          setDocumentCount({ ...data.documentCount });
          setTourRequestCount({ ...data.tourRequestCount });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className='dashboard-container info-section'>
      <Box
        width='100%'
        sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography variant='h4'>
          {greeting()} {currentUser?.firstName}
        </Typography>
        {(role === "Parent" || role === "Employee") && (
          <Typography variant='h5'>
            Welcome to Radiant Montessori {role} Portal
          </Typography>
        )}

        {role === "SuperAdmin" && (
          <Button
            sx={{ width: "max-content" }}
            variant='contained'
            onClick={authDropbox}>
            Get Access to dropbox
          </Button>
        )}
        {(role === "SuperAdmin" || role === "Admin") && (
          <Card sx={{ overflow: "unset" }}>
            <CardContent>
              <Typography
                variant='h5'
                sx={{ paddingBottom: "1rem" }}
                color='text.secondary'
                gutterBottom>
                Tour Requests
              </Typography>
              <Box
                display={"flex"}
                flexWrap={"wrap"}
                justifyContent={"space-evenly"}
                gap={"2rem"}>
                <CountCards
                  type={"tour-requests"}
                  count={tourRequestCount.New}
                  color={"#b39ddb"}
                  title='new'></CountCards>
                <CountCards
                  type={"tour-requests"}
                  count={tourRequestCount.Scheduled}
                  color={"#ffcc80"}
                  title='scheduled'></CountCards>
                <CountCards
                  type={"tour-requests"}
                  count={tourRequestCount.Visited}
                  color={"#80deea"}
                  title='visited'></CountCards>
                <CountCards
                  type={"tour-requests"}
                  count={tourRequestCount.Joined}
                  color={"#80deea"}
                  title='enrolled'></CountCards>
                <CountCards
                  type={"tour-requests"}
                  count={tourRequestCount.Canceled}
                  color={"#c5e1a5"}
                  title='canceled'></CountCards>
              </Box>
            </CardContent>
          </Card>
        )}
        <Card sx={{ overflow: "unset" }}>
          <CardContent>
            <Typography
              variant='h5'
              sx={{ paddingBottom: "1rem" }}
              color='text.secondary'
              gutterBottom>
              Documents
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-evenly"}
              flexWrap={"wrap"}
              gap={"2rem"}>
              <CountCards
                type={"documents"}
                count={documentCount.Pending}
                color={"#ffdc8a"}
                title='pending'></CountCards>
              <CountCards
                type={"documents"}
                count={documentCount.Completed}
                color={"#caffad"}
                title='completed'></CountCards>
              <CountCards
                type={"documents"}
                count={documentCount.Expired}
                color={"#ffd0f7"}
                title='expired'></CountCards>
              <CountCards
                type={"documents"}
                count={documentCount.Rejected}
                color={"#e1a5a5"}
                title='rejected'></CountCards>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default Dashboard;
