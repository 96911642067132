import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ParentsService from "../../services/parents-management.service";

const initialState = {
  parents: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  selectedParentId: null,
  selectedParent: null,
  isModalOpen: false,
};

export const fetchAllParents = createAsyncThunk(
  "parents/fetchAll",
  async () => {
    const response = await ParentsService.getAllParents();
    return response;
  }
);
export const fetchParentById = createAsyncThunk(
  "parents/fetchOne",
  async ({ id }) => {
    const response = await ParentsService.getParentById(id);
    return response;
  }
);
export const createParent = createAsyncThunk("parents/create", async (data) => {
  const response = await ParentsService.createParent(data);
  return response;
});
export const updateParent = createAsyncThunk(
  "parents/update",
  async ({ id, parentData }) => {
    const response = await ParentsService.updateParent(id, parentData);
    return response;
  }
);

export const parentsSlice = createSlice({
  name: "parents",
  initialState,
  reducers: {
    clearSelectedParent: (state) => {
      state.selectedParent = null;
    },
    selectParent: (state, { payload }) => {
      state.selectedParentId = payload.id;
    },
    openParentModal: (state) => {
      state.isModalOpen = true;
    },
    closeParentModal: (state) => {
      state.isModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get All
      .addCase(fetchAllParents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllParents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.parents = [...action?.payload];
      })
      .addCase(fetchAllParents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Get By Id
      .addCase(fetchParentById.pending, (state, action) => {
        state.status = "loading";
        state.selectedParentId = action?.meta?.arg;
      })
      .addCase(fetchParentById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedParent = { ...action?.payload };
      })
      .addCase(fetchParentById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Create
      .addCase(createParent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createParent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isModalOpen = false;
        state.parents = [...state.parents, action?.payload];
      })
      .addCase(createParent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Update
      .addCase(updateParent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateParent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isModalOpen = false;
        state.parents = [
          ...state.parents.map((e) =>
            e.id === action.payload.result.id
              ? {
                  ...e,
                  fullName: action.payload.result.fullName,
                  email: action.payload.result.email,
                  phoneNumber: action.payload.result.phoneNumber,
                  isDeleted: action.payload.result,
                }
              : e
          ),
        ];
        state.selectedParent = null;
      })
      .addCase(updateParent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  clearSelectedParent,
  selectParent,
  openParentModal,
  closeParentModal,
} = parentsSlice.actions;

//Selectors
export const getSelectedParent = (state) => state?.parents?.selectedParent;
export const getAllParents = (state) => state.parents.parents;
export const getSelectedParentId = (state) => state.selectedParentId;
export const getParentModalStatus = (state) => state.isModalOpen;

export default parentsSlice.reducer;
