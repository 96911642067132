import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ChildrenService from "../../services/children-management-service";

const initialState = {
  childrenList: [],
  childrenParentsSummeryList: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  selectedChild: null,
};

export const fetchChildren = createAsyncThunk("children/fetchAll", async () => {
  const response = await ChildrenService.getAllChildren();
  return response;
});
export const fetchChild = createAsyncThunk("children/fetchById", async (id) => {
  const response = await ChildrenService.getChildById(id);
  return response;
});
export const addChild = createAsyncThunk(
  "children/create",
  async (childData) => {
    const response = await ChildrenService.createChild(childData);
    return response;
  }
);
export const editChild = createAsyncThunk(
  "children/update",
  async (childData) => {
    const response = await ChildrenService.updateChildById(
      childData.id,
      childData
    );
    return response;
  }
);
export const deleteChildById = createAsyncThunk(
  "children/delete",
  async ({id, reason, note}) => {
    const response = await ChildrenService.deleteChildById(id, reason, note);
    return response;
  }
);
export const fetchChildParentSummeryList = createAsyncThunk(
  "children/fetchChildParentSummeryList",
  async (id) => {
    const response = await ChildrenService.getChildrenParentsSummeryList();
    return response;
  }
);

export const childrenSlice = createSlice({
  name: "children",
  initialState,
  reducers: {
    selectChild: (state, { payload }) => {
      state.selectedChild = state.childrenList.find(
        (child) => child.id === payload.id
      );
    },
    clearSelectedChild: (state) => {
      state.selectedChild = null;
    },
  },
  extraReducers(builder) {
    builder
      // Add
      .addCase(addChild.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addChild.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.childrenList = [action?.payload, ...state.childrenList];
      })
      .addCase(addChild.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch all
      .addCase(fetchChildren.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChildren.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.childrenList = [...action?.payload];
      })
      .addCase(fetchChildren.rejected, (state, action) => {
        state.status = "failed";
        state.childrenList = [];
        state.error = action.error.message;
      })
      // Fetch By ID
      .addCase(fetchChild.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChild.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedChild = action?.payload;
      })
      .addCase(fetchChild.rejected, (state, action) => {
        state.status = "failed";
        state.selectedChild = null;
        state.error = action.error.message;
      })
      // Edit
      .addCase(editChild.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editChild.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.childrenList = state.childrenList.map((child) =>
          child.id === action?.payload?.id
            ? {
                ...child,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                classroom: action.payload.classRoom,
              }
            : child
        );
        state.selectedChild = null;
      })
      .addCase(editChild.rejected, (state, action) => {
        state.status = "failed";
        state.selectedChild = null;
        state.error = action.error.message;
      })
      // Delete
      .addCase(deleteChildById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteChildById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.childrenList = state.childrenList.filter(
          (c) => c.id !== action.meta.arg.id
        );
        state.selectedChild = null;
      })
      .addCase(deleteChildById.rejected, (state, action) => {
        state.status = "failed";
        state.selectedChild = null;
        state.error = action.error.message;
      })
      // Fetch all summary
      .addCase(fetchChildParentSummeryList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChildParentSummeryList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.childrenParentsSummeryList = [...action?.payload];
      })
      .addCase(fetchChildParentSummeryList.rejected, (state, action) => {
        state.status = "failed";
        state.childrenParentsSummeryList = [
          ...state.childrenParentsSummeryList,
        ];
        state.error = action.error.message;
      });
  },
});

// // Action creators are generated for each case reducer function
export const { clearSelectedChild, selectChild } = childrenSlice.actions;

//Selectors
export const getAllChildren = (state) => state.children?.childrenList;
export const getSelectedChild = (state) => state.children.selectedChild;
export const getChildParentsSummeryList = (state) =>
  state.children.childrenParentsSummeryList;
export const getError = (state) => state.children.error;
export const getStatus = (state) => state.children.status;

export default childrenSlice.reducer;
