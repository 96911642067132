import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { Fragment, forwardRef, useEffect, useState } from "react";
import ModalContent from "../../components/Modal/ModalContent";
import {
  HearAboutUsMap,
  TourRequestStatusMap,
} from "../../enumMaps/tourRequestEnum.map";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedTourRequest,
  sendEnrolmentDocuments,
  setTourRequestScheduleDates,
  updateTourRequest,
} from "./TourRequestSlice";
import { TourRequestStatusEnum } from "../../enums/TourRequestStatus.enum";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { getLoggedUserRoles } from "../auth/AuthSlice";
import ParentsResponse from "./ParentsResponse";
import { TourRequestEnrollmentStatusEnum } from "../../enums/ToureRequestEnrollmentStatus.enum";

const header = (status) => (
  <Box sx={{ display: "flex", gap: "2rem", alignItems: "center" }}>
    <Typography variant="h5">Tour Request</Typography>
    {status ? (
      <Chip
        // size='small'
        label={TourRequestStatusMap.get(status).label}
        color={TourRequestStatusMap.get(status).color}
        // variant={TourRequestStatusMap.get(status).variant}
        icon={TourRequestStatusMap.get(status).icon}
      />
    ) : (
      ""
    )}
  </Box>
);

const TourRequestInfoModal = (props) => {
  const dispatch = useDispatch();
  const tourRequest = useSelector(getSelectedTourRequest);
  const [availableDates, setAvailableDates] = useState([null, null, null]);
  const [isDateError, setDateError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTourCompletion, setOpenTourCompletion] = useState(props.isOpen);
  const [earliestAvailability, setEarliestAvailability] = useState(null);
  const [error, setError] = useState(null);
  const [isTourFixed, setIsTourFixed] = useState(false);
  const currentUserRoles = useSelector(getLoggedUserRoles);
  const TODAY = new Date();
  const MONTHS = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const MIN_MONTH = TODAY.getUTCFullYear() + "-" + MONTHS[TODAY.getMonth()];

  useEffect(() => {
    if (tourRequest) {
      setAvailableDates(
        tourRequest?.scheduledDates && tourRequest?.scheduledDates.length > 0
          ? [...tourRequest?.scheduledDates]
          : [null, null, null]
      );

      setIsTourFixed(
        !(
          tourRequest.status === TourRequestStatusEnum.Pending ||
          tourRequest.status === TourRequestStatusEnum.Responded ||
          tourRequest.status === TourRequestStatusEnum.ScheduleRequested ||
          tourRequest.status === TourRequestStatusEnum.Scheduled ||
          tourRequest.status === TourRequestStatusEnum.Rescheduled
        )
      );
    }
  }, [tourRequest]);

  const sendEarliestAvailableMonth = () => {
    if (earliestAvailability) {
      setError(null);
      dispatch(
        updateTourRequest({
          id: tourRequest.id,
          earliestAvailability: earliestAvailability + "-01",
        })
      );
      setOpen(false);
      setEarliestAvailability(null);
    } else {
      setError("Please select a month");
    }
  };
  const closeAvailability = () => {
    setEarliestAvailability(null);
    setOpen(false);
    setError(null);
  };

  const sendScheduledDates = () => {
    if (availableDates.some((d) => !!d)) {
      setDateError(false);
      dispatch(
        setTourRequestScheduleDates({
          id: tourRequest.id,
          dates: availableDates.filter((d) => !!d),
        })
      );
    } else {
      setDateError(true);
    }
  };

  const onChangeAvailableDates = (index, date) => {
    setAvailableDates((prevDates) =>
      prevDates.map((d, i) => (i === index ? date : d))
    );
  };

  const addNewAvailableDate = () => {
    setAvailableDates((prevDates) => [...prevDates, null]);
  };
  const removeAvailableDate = (index) => {
    setAvailableDates((prevDates) => prevDates.filter((d, i) => i !== index));
  };

  const getEnrollmentDate = () => {
    switch (tourRequest?.enrollmentStatus) {
      case TourRequestEnrollmentStatusEnum.UNDECIDED:
        return "Undecided";

      case TourRequestEnrollmentStatusEnum.ENROLLING_NOW:
        return new Date(tourRequest.enrollmentDate ).toLocaleString(
          "en-us",
          {
            year: "numeric",
            month: "short",
            day: "numeric",
          }
        );

      case TourRequestEnrollmentStatusEnum.ENROLLING_FUTURE:
        return new Date(tourRequest.enrollmentDate ).toLocaleString(
          "en-us",
          {
            year: "numeric",
            month: "long",
          }
        );

      default:
        return "";
    }
  };

  const cardContent = () => (
    <Fragment>
      <Box sx={{ padding: "2rem 0", fontSize: 14 }}>
        <Divider textAlign="left">
          <Chip label="Parent Details" />
        </Divider>
        <Grid container p={3} rowSpacing={1} columnSpacing={{ sm: 1, md: 2 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="caption" fontSize={14}>
              Parent Name :{" "}
            </Typography>
            {tourRequest?.parentFirstName + " " + tourRequest?.parentLastName}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption" fontSize={14}>
              Parent Email :{" "}
            </Typography>
            {tourRequest?.parentEmail}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption" fontSize={14}>
              Parent Phone Number :{" "}
            </Typography>
            {tourRequest?.parentPhoneNumber}
          </Grid>
        </Grid>

        <Divider textAlign="left">
          <Chip label="Child Details" />
        </Divider>
        {tourRequest?.children.map((child, index) => {
          return (
            <Fragment key={index}>
              <Grid
                container
                p={3}
                rowSpacing={1}
                columnSpacing={{ sm: 1, md: 2 }}
              >
                <Grid item xs={12} md={6}>
                  <Typography variant="caption" fontSize={14}>
                    Child Name :{" "}
                  </Typography>
                  {child?.firstName + " " + child?.lastName}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="caption" fontSize={14}>
                    Date of Birth :{" "}
                  </Typography>
                  {new Date(child?.dob)?.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="caption" fontSize={14}>
                    Desired Start Date :{" "}
                  </Typography>
                  {new Date(child?.desiredStartDate)?.toLocaleDateString(
                    "en-US"
                  )}
                </Grid>
              </Grid>
              {tourRequest.children.length > index + 1 ? <Divider /> : ""}
            </Fragment>
          );
        })}

        <Divider textAlign="left">
          <Chip label="Request Details" />
        </Divider>
        <Grid container p={3} rowSpacing={1} columnSpacing={{ sm: 1, md: 2 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="caption" fontSize={14}>
              Date of first Contact:{" "}
            </Typography>
            {new Date(tourRequest?.createdDate)?.toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="caption" fontSize={14}>
              Heard About Us From :{" "}
            </Typography>
            {HearAboutUsMap.get(tourRequest?.heardAboutUsMethod)}
            <br />
            <Typography variant="body2" pl={1}>
              {tourRequest?.heardAbutUsDescription}
            </Typography>
          </Grid>

          {tourRequest?.earliestAvailability &&
            (tourRequest.status === TourRequestStatusEnum.Responded ||
              tourRequest.status ===
                TourRequestStatusEnum.ScheduleRequested) && (
              <Grid item xs={12} md={6}>
                <Typography variant="caption" fontSize={14}>
                  Earliest Availability:{" "}
                </Typography>
                <strong>
                  {new Date(
                    tourRequest?.earliestAvailability + "T00:00"
                  )?.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </strong>
              </Grid>
            )}

          {isTourFixed && (
            <Grid item xs={12} md={6}>
              <Typography variant="caption" fontSize={14}>
                Tour Schedule :{" "}
              </Typography>
              {tourRequest?.visitDate !== null && (
                <strong>
                  {new Date(tourRequest?.visitDate).toLocaleString("en-us", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    // timeZone: "America/Chicago",
                  })}
                </strong>
              )}
            </Grid>
          )}
          {(tourRequest.status === TourRequestStatusEnum.Visited ||
            tourRequest.status === TourRequestStatusEnum.EnrolmentInitiated ||
            tourRequest.status === TourRequestStatusEnum.Enrolled) && (
            <Grid item xs={12} md={6}>
              <Typography variant="caption" fontSize={14}>
                Decided Enrolment Date :{" "}
              </Typography>
              <strong>{getEnrollmentDate()}</strong>
            </Grid>
          )}
        </Grid>

        {!isTourFixed && (
          <Fragment>
            <Divider textAlign="left">
              <Chip
                label="Available Dates for the Tour"
                deleteIcon={<AddCircleIcon />}
                onDelete={addNewAvailableDate}
              />
            </Divider>
            <Box p={3}>
              <Typography
                variant="caption"
                fontSize={14}
                color={isDateError ? "error" : "default"}
              >
                {tourRequest?.status === TourRequestStatusEnum.Pending ||
                tourRequest?.status === TourRequestStatusEnum.Responded ||
                isDateError
                  ? "Please select three available dates for the tour and send to parent."
                  : tourRequest?.status === TourRequestStatusEnum.Scheduled ||
                    tourRequest?.status === TourRequestStatusEnum.Rescheduled
                  ? "Current Scheduled Tour Dates:"
                  : "Scheduled Tour Dates:"}
              </Typography>
              {(tourRequest?.status === TourRequestStatusEnum.Scheduled ||
                tourRequest?.status === TourRequestStatusEnum.Rescheduled) && (
                <Typography variant="subtitle1" fontSize={15}>
                  {" "}
                  Select new dates and send re-scheduled dates to parent
                </Typography>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  gap: "3rem",
                }}
              >
                <Box>
                  {availableDates?.map((date, index) => {
                    return (
                      <Box
                        key={"available-date-" + index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <Typography>{index + 1}.</Typography>

                        <>
                          <TextField
                            size="small"
                            id={`date-${index}`}
                            type="datetime-local"
                            variant="standard"
                            // defaultValue={date ? date : new Date()}
                            value={date ? date : new Date()}
                            inputProps={{
                              min: `${new Date().toISOString()}`,
                            }}
                            onInput={(event) =>
                              onChangeAvailableDates(index, event.target.value)
                            }
                          />
                          <IconButton
                            aria-label="delete"
                            size="large"
                            onClick={() => removeAvailableDate(index)}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </>
                      </Box>
                    );
                  })}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
              </Box>
            </Box>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );

  const onUpdateStatus = (id, status) => {
    dispatch(
      updateTourRequest({
        id: tourRequest.id,
        status: status,
      })
    );
  };

  const openParentsResponse = () => {
    setOpenTourCompletion(true);
  };

  const closeParentsResponse = () => {
    setOpenTourCompletion(false);
  };

  const actions = (tourRequestId, status) => {
    switch (status) {
      case TourRequestStatusEnum.Pending:
      case TourRequestStatusEnum.Responded:
      case TourRequestStatusEnum.ScheduleRequested:
        return (
          <>
            {!isTourFixed && (
              <Button
                size="small"
                variant="contained"
                onClick={() => setOpen(true)}
              >
                Send Earliest Availability
              </Button>
            )}
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => sendScheduledDates()}
            >
              Send Schedule
            </Button>
          </>
        );
      case TourRequestStatusEnum.Scheduled:
      case TourRequestStatusEnum.Rescheduled:
        return (
          <>
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => sendScheduledDates()}
            >
              Send Schedule
            </Button>
            {/* <Button
              size='small'
              variant='outlined'
              color='success'
              onClick={() =>
                onUpdateStatus(tourRequestId, TourRequestStatusEnum.Visited)
              }>
              Tour Completed
            </Button> */}
            {/* <Button
              size='small'
              variant='outlined'
              onClick={() =>
                dispatch(sendEnrolmentDocuments({ id: tourRequestId }))
              }>
              Send Initial Documents
            </Button> */}
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() =>
                onUpdateStatus(tourRequestId, TourRequestStatusEnum.Canceled)
              }
            >
              No Response
            </Button>
          </>
        );
      case TourRequestStatusEnum.Confirmed:
        return (
          <>
            <Button
              size="small"
              variant="outlined"
              color="success"
              onClick={openParentsResponse}
            >
              Tour Completed
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() =>
                onUpdateStatus(tourRequestId, TourRequestStatusEnum.Canceled)
              }
            >
              No Show
            </Button>
          </>
        );
      case TourRequestStatusEnum.Visited:
        return (
          <>
            <Button
              size="small"
              variant="outlined"
              onClick={() =>
                dispatch(sendEnrolmentDocuments({ id: tourRequestId }))
              }
            >
              Send Initial Documents
            </Button>
            {/* <Button
              size='small'
              variant='contained'
              color='error'
              onClick={() =>
                onUpdateStatus(tourRequestId, TourRequestStatusEnum.Canceled)
              }>
              No Show
            </Button> */}
          </>
        );
      case TourRequestStatusEnum.EnrolmentInitiated:
        if (currentUserRoles.includes("SuperAdmin"))
          return (
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() =>
                onUpdateStatus(tourRequestId, TourRequestStatusEnum.Enrolled)
              }
            >
              Mark as Enrolled
            </Button>
          );
        else
          return (
            <Button
              size="small"
              color="error"
              variant="outlined"
              onClick={() => props.modalCloseHandle()}
            >
              Close
            </Button>
          );
      case TourRequestStatusEnum.Enrolled:
      case TourRequestStatusEnum.Canceled:
      case TourRequestStatusEnum.Expired:
      default:
        return (
          <Button
            size="small"
            color="error"
            variant="outlined"
            onClick={() => props.modalCloseHandle()}
          >
            Close
          </Button>
        );
    }
  };

  return (
    <>
      <ModalContent
        header={header(tourRequest?.status)}
        content={tourRequest === null ? null : cardContent(tourRequest)}
        action={actions(tourRequest?.id, tourRequest?.status)}
        handleClose={props.modalCloseHandle}
        error={error}
        // isOpen={open}
      />

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        sx={{ minHeight: "50vh" }}
        open={open}
        onClose={closeAvailability}
        aria-labelledby="tour-request-info"
      >
        <DialogContent>
          <DialogContentText>
            Please select earliest available month for the enrolment.
          </DialogContentText>
          <Fragment>
            <TextField
              autoFocus
              margin="dense"
              id="earliest-available"
              type="month"
              fullWidth
              variant="standard"
              inputProps={{ min: MIN_MONTH }}
              onInput={(e) => setEarliestAvailability(e.target.value)}
            />
            {error != null && (
              <Typography variant="caption" color={"error"}>
                {error}
              </Typography>
            )}
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAvailability}>Cancel</Button>
          <Button onClick={() => sendEarliestAvailableMonth()}>
            Send Email
          </Button>
        </DialogActions>
      </Dialog>

      {openTourCompletion && (
        <ParentsResponse
          isOpen={openTourCompletion}
          request={tourRequest}
          MIN_MONTH={MIN_MONTH}
          MONTHS={MONTHS}
          onClose={closeParentsResponse}
        />
      )}
    </>
  );
};

TourRequestInfoModal.propTypes = {
  id: PropTypes.string.isRequired,
  modalCloseHandle: PropTypes.func.isRequired,
};

export default forwardRef((props, ref) => (
  <TourRequestInfoModal {...props} forwardedRef={ref} />
));
