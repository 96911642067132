import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllParents,
  getAllParents,
} from "../parents-management/ParentsSlice";
import ModalContent from "../../components/Modal/ModalContent";
import PropTypes from "prop-types";
import { addChild, getStatus } from "./childrenSlice";

const AddChildModal = (props) => {
  const [selectedParent, setSelectedParent] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const formRef = React.useRef(null);

  let parentsList = useSelector(getAllParents);
  let status = useSelector(getStatus);

  useEffect(() => {
    if (!parentsList || parentsList?.length === 0) dispatch(fetchAllParents());
  }, [parentsList, dispatch]);

  useEffect(() => {
    if (isSubmitted && status === "succeeded") {
      props.modalCloseHandle();
      setIsSubmitted(false);
    }
  }, [isSubmitted, status, props]);

  const onClickCreateChild = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSelectParent = (value) => {
    setSelectedParent(value);
  };

  const onSubmit = async (d) => {
    let childData = {
      firstName: d.firstName,
      lastName: d.lastName,
      dob: d.dob,
      classroom: d.classroom,
      parentId: selectedParent?.id,
    };
    dispatch(addChild(childData));
    setIsSubmitted(true);
  };

  const content = (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit((formData) => onSubmit(formData))}
      ref={formRef}
    >
      <Grid p={2} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            options={parentsList}
            getOptionLabel={(option) => `${option.fullName}`}
            onChange={(event, value) => onSelectParent(value)}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.fullName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...register(`parent`, {
                  required: "Select the child's parent.",
                })}
                {...params}
                label="Parent"
                error={errors?.parent !== undefined ? errors?.parent : false}
                helperText={
                  errors?.parent !== undefined ? errors?.parent?.message : ""
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            {...register(`firstName`, {
              required: "Child's first name is required.",
            })}
            label={"Child's First Name*"}
            error={errors?.firstName !== undefined ? errors?.firstName : false}
            helperText={
              errors?.firstName !== undefined ? errors?.firstName?.message : ""
            }
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            {...register(`lastName`, {
              required: "Child's last name is required.",
            })}
            label={"Last Name*"}
            error={errors?.lastName !== undefined ? errors?.lastName : false}
            helperText={
              errors?.lastName !== undefined ? errors?.lastName?.message : ""
            }
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            type={"date"}
            inputProps={{
              inputMode: "date",
              max: new Date().toISOString().split("T")[0],
            }}
            {...register(`dob`, {
              required: "Child's date of birth is required.",
              valueAsDate: true,
              max: {
                value: new Date(),
                message: "Child's date of birth must be before today.",
              },
            })}
            label={"Date of Birth*"}
            error={errors?.dob !== undefined ? errors.dob : false}
            helperText={errors?.dob !== undefined ? errors?.dob?.message : ""}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            {...register(`classroom`, {
              required: "Select the classroom to enroll.",
            })}
            label="Classroom"
            error={errors?.classroom !== undefined ? errors?.classroom : false}
            helperText={
              errors?.classroom !== undefined ? errors?.classroom?.message : ""
            }
          >
            <MenuItem value={0}>Preschool</MenuItem>
            <MenuItem value={1}>Toddler</MenuItem>
            <MenuItem value={2}>Transition Room</MenuItem>
            <MenuItem value={3}>Infants</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );

  const actions = (
    <Fragment>
      <Button onClick={onClickCreateChild} variant="contained" color="success">
        Create Child
      </Button>
    </Fragment>
  );

  return (
    <ModalContent
      title={"Add a Child"}
      content={content}
      action={actions}
      handleClose={props.modalCloseHandle}
    />
  );
};
AddChildModal.propTypes = {
  modalCloseHandle: PropTypes.func.isRequired,
};

export default AddChildModal;
