import { Box, Button, Typography } from "@mui/material";
import React from "react";
import "./countCard.scss";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const CountCards = ({ count, title, color, type }) => {
  const navigate = useNavigate();
  const onclickCard = (type, status) => {
    navigate("/" + type + "?status=" + status);
  };

  const CardButton = styled(Button)({
    width: "7rem",
    minHeight: "6rem",
    borderRadius: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: " 1rem",
    textTransform: "capitalize",
    color: "black",
  });

  return (
    <CardButton
      onClick={() => onclickCard(type, title)}
      variant='outlined'
      sx={{
        background: color,
        border: "1px sold",
        borderColor: color,
        "&:hover": {
          borderColor: color,
          background: color + "A0",
        },
      }}>
      <Typography
        variant='h6'
        component='h1'>
        {title}
      </Typography>
      <Typography
        className='count'
        variant='h2'
        component='h2'
        sx={{ fontSize: "1.7rem" }}>
        {count}
      </Typography>
    </CardButton>
  );
};

export default CountCards;
