import React from "react";
import PropTypes from "prop-types";
import ModalContent from "../../components/Modal/ModalContent";
import { Button } from "@mui/material";
import EmployeeForm from "./EmployeeForm";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedEmployee, updateEmployee } from "./EmployeeSlice";

const EditEmployeeModal = (props) => {
  const formRef = React.useRef(null);
  const dispatch = useDispatch();
  const employee = useSelector(getSelectedEmployee);

  const onClickCreateEmployee = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const handleSubmit = async (d) => {
    let newEmployeeData = {
      ...d,
    };

    dispatch(
      updateEmployee({ id: employee.id, employeeData: newEmployeeData })
    );
  };

  const content = (
    <EmployeeForm
      onSubmit={handleSubmit}
      formRef={formRef}
      defaultValue={employee}
    />
  );

  const actions = (
    <React.Fragment>
      <Button
        onClick={onClickCreateEmployee}
        variant='contained'>
        Update Employee
      </Button>
    </React.Fragment>
  );

  return (
    <ModalContent
      title={"Edit Employee"}
      content={content}
      action={actions}
      handleClose={props.modalCloseHandle}
    />
  );
};

EditEmployeeModal.propTypes = {
  modalCloseHandle: PropTypes.func.isRequired,
};

export default React.forwardRef((props, ref) => (
  <EditEmployeeModal
    {...props}
    forwardedRef={ref}
  />
));
