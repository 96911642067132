import { forwardRef, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";

const ParentForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const childFields = (child, index) => {
    const fieldName = `children[${child}]`;
    return (
      <Fragment key={fieldName}>
        <Divider textAlign='left'>
          <Chip
            label={`Child #${index + 1}`}
            size='small'
          />
        </Divider>
        <Grid
          p={2}
          container
          spacing={2}>
          <Grid
            item
            xs={12}
            md={6}>
            <TextField
              fullWidth
              {...register(`${fieldName}.firstName`, {
                required: "Child's first name is required.",
              })}
              label={"First Name*"}
              error={
                errors?.children && errors?.children[child] !== undefined
                  ? errors?.children[child].firstName
                  : false
              }
              helperText={
                errors?.children !== undefined
                  ? errors?.children[child]?.firstName?.message
                  : ""
              }></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <TextField
              fullWidth
              {...register(`${fieldName}.lastName`, {
                required: "Child's last name is required.",
              })}
              label={"Last Name*"}
              error={
                errors?.children && errors?.children[child] !== undefined
                  ? errors?.children[child].lastName
                  : false
              }
              helperText={
                errors?.children !== undefined
                  ? errors?.children[child]?.lastName?.message
                  : ""
              }></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              type={"date"}
              inputProps={{
                inputMode: "date",
                max: new Date().toISOString().split("T")[0],
              }}
              {...register(`${fieldName}.dob`, {
                required: "Child's date of birth is required.",
                valueAsDate: true,
                max: {
                  value: new Date(),
                  message: "Child's date of birth must be before today.",
                },
              })}
              label={"Date of Birth*"}
              error={
                errors?.children && errors?.children[child] !== undefined
                  ? errors?.children[child].dob
                  : false
              }
              helperText={
                errors?.children !== undefined
                  ? errors?.children[child]?.dob?.message
                  : ""
              }></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <TextField
              select
              fullWidth
              {...register(`${fieldName}.classroom`, {
                required: "Child's classroom required.",
              })}
              label='Classroom'
              error={
                errors?.children && errors?.children[child] !== undefined
                  ? errors?.children[child].classroom
                  : false
              }
              helperText={
                errors?.children !== undefined
                  ? errors?.children[child]?.classroom?.message
                  : ""
              }>
              <MenuItem value={1}>Preschool</MenuItem>
              <MenuItem value={2}>Toddler</MenuItem>
              <MenuItem value={3}>Transition Room</MenuItem>
              <MenuItem value={4}>Infants</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        {props.children?.length > 1 && (
          <Button
            variant='outlined'
            color='error'
            size='small'
            sx={{ ml: 3, mb: 3 }}
            onClick={() => props.removeChildHandle(child)}>
            Remove
          </Button>
        )}
      </Fragment>
    );
  };
  return (
    <Box
      component='form'
      autoComplete='off'
      onSubmit={handleSubmit(props.onSubmit)}
      ref={props.formRef}
      //   noValidate
    >
      <Grid
        p={2}
        container
        spacing={2}>
        <Grid
          item
          xs={12}
          md={6}>
          <TextField
            fullWidth
            {...register(`firstName`, {
              required: "Parent's first name is required.",
            })}
            label={"First Name*"}
            error={errors?.firstName !== undefined ? errors?.firstName : false}
            helperText={
              errors?.firstName !== undefined ? errors?.firstName?.message : ""
            }></TextField>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <TextField
            fullWidth
            {...register(`lastName`, {
              required: "Parent's last name is required.",
            })}
            label={"Last Name*"}
            error={errors?.lastName !== undefined ? errors?.lastName : false}
            helperText={
              errors?.lastName !== undefined ? errors?.lastName?.message : ""
            }></TextField>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <TextField
            fullWidth
            {...register(`email`, {
              required: "Parent's email is required.",
            })}
            label={"E-Mail*"}
            error={errors?.email !== undefined ? errors?.email : false}
            helperText={
              errors?.email !== undefined ? errors?.email?.message : ""
            }></TextField>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <TextField
            fullWidth
            {...register(`phoneNumber`, {
              required: "Parent's phone number is required.",
              maxLength: {
                value: 17,
                message:
                  "Phone number must be 10 digits as (xxx) xxx-xxxx without brackets and spaces.",
              },
              // onChange: (e) =>{
              //   e.target.value = autoFormatPhoneNumber(e.target.value);
              // },
            })}
            label={"Phone Number*"}
            error={
              errors?.phoneNumber !== undefined ? errors?.phoneNumber : false
            }
            helperText={
              errors?.phoneNumber !== undefined
                ? errors?.phoneNumber?.message
                : ""
            }></TextField>
        </Grid>
      </Grid>
      {props.children.map((child, index) => childFields(child, index))}
    </Box>
  );
};
ParentForm.propTypes = {
  formRef: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
  index: PropTypes.func,
};

export default forwardRef((props, ref) => (
  <ParentForm
    {...props}
    forwardedRef={ref}
  />
));
// eslint-disable-next-line no-unused-vars
function autoFormatPhoneNumber(phoneNumberString) {
  try {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{0,3})?(\d{0,3})?(\d{0,4})?$/);
    var intlCode = match[1] ? "+1 " : "";
    return [
      intlCode,
      match[2] ? "(" : "",
      match[2],
      match[3] ? ") " : "",
      match[3],
      match[4] ? "-" : "",
      match[4],
    ].join("");
  } catch (err) {
    return "";
  }
}