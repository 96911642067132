import axios from "../config/API";
import { trackPromise } from "react-promise-tracker";

export const getAllChildren = async () => {
  try {
    let param='';
    // param+='?status=0';
    // param+='&status=1';
    // param+='&status=2';
    // param+='&status=3';
    
    const result = await trackPromise(axios.get("/children"+param));
    const data = result.data;
    if (data.isSuccess) {
      return data.payload;
    }
  } catch (err) {
    throw err;
  }
};
export const getChildrenParentsSummeryList = async () => {
  try {
    const result = await trackPromise(
      axios.get("/children/allChildrenParentSummery")
    );
    const data = result.data;
    if (data.isSuccess) {
      return data.payload;
    }
  } catch (err) {
    throw err;
  }
};
export const getChildById = async (id) => {
  try {
    const result = await trackPromise(axios.get(`/children/${id}`));
    const data = result.data;
    if (data.isSuccess) {
      return data.payload;
    }
  } catch (err) {
    throw err;
  }
};
export const createChild = async (childData) => {
  try {
    const result = await trackPromise(axios.post(`/children/`, childData));
    const data = result.data;
    if (data.isSuccess) {
      return data.payload;
    }
  } catch (err) {
    throw err;
  }
};
export const updateChildById = async (id, childData) => {
  try {
    const result = await trackPromise(axios.put(`/children/${id}`, childData));
    const data = result.data;
    if (data.isSuccess) {
      return data.payload;
    }
  } catch (err) {
    throw err;
  }
};
export const deleteChildById = async (id, reason, removingNote) => {
  const formData = new FormData();
  formData.append("status", reason);
  formData.append("note", removingNote);
  try {
    const result = await trackPromise(
      axios.delete(`/children/${id}/remove`, { data: formData })
    );
    const data = result.data;
    if (data.isSuccess) {
      return data.payload;
    }
    return Promise.reject(data.message);
  } catch (err) {
    throw err;
  }
};

const ChildrenService = {
  getAllChildren,
  getChildById,
  createChild,
  updateChildById,
  deleteChildById,
  getChildrenParentsSummeryList,
};

export default ChildrenService;
