import React, { useEffect, useState } from "react";
import {
  getTourScheduleById,
  confirmTourSchedule,
  requestTimeSlot,
} from "../../services/tour-request-management.service";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import useTitle from "../../hooks/useTitle";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../layout/layoutSlice";

const cardStyle = {
  padding: "2rem",
  borderRadius: "3rem",
  background: "#ffffff",
  boxShadow: "20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff",
};
const backgroundStyle = {
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 2rem",
  background: "#ffffff",
};

const TourConfirmPage = () => {
  useTitle("Confirm Tour");
  const { id } = useParams();
  const dispatch = useDispatch();

  const [slots, setSlots] = useState([]);
  const [fixedVisitDate, setFixedVisitDate] = useState(null);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(false);
  const [visitDateConfirmed, setVisitDateConfirmed] = useState(false);
  const [scheduleRequested, setScheduleRequested] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTourScheduleById(id)
      .then((data) => {
        setLoading(false);
        if (!!data) {
          if (!!data.fixedVisitDate) {
            setFixedVisitDate(data.fixedVisitDate);
            setVisitDateConfirmed(true);
          } else {
            setSlots(data.availableTimeSlots);
            if (data.availableTimeSlots?.length === 1) {
              setValue(data.availableTimeSlots[0]);
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          openSnackbar({
            isOpen: true,
            message: err,
            severity: "error",
          })
        );
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const requestNew = () => {
    requestTimeSlot(id)
      .then((data) => {
        if (data) {
          setScheduleRequested(true);
        }
      })
      .catch((err) => {
        dispatch(
          openSnackbar({
            isOpen: true,
            message: err,
            severity: "error",
          })
        );
      });
  };

  const confirm = () => {
    if (!value && slots?.length > 1) {
      setError(true);
    } else {
      setError(false);

      confirmTourSchedule(id, value).then((data) => {
        if (data) {
          setVisitDateConfirmed(true);
          setFixedVisitDate(data);
        }
      });
    }
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setError(false);
  };
  if (loading) return null;
  else
    return (
      <Box sx={backgroundStyle}>
        {!visitDateConfirmed && !scheduleRequested && slots?.length > 1 && (
          <Box sx={cardStyle}>
            <Typography variant="h4">Confirm Tour Schedule</Typography>
            <Box
              style={{
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
                maxWidth: "50rem",
              }}
            >
              <Typography variant="body1">
                Please Select one time slot and press confirm to fix the Radiant
                Montessori tour.
              </Typography>
              <Typography variant="h6">Time Slots</Typography>
              <Box paddingLeft={1}>
                <RadioGroup
                  name="time-slots"
                  value={value}
                  onChange={handleRadioChange}
                >
                  {slots?.map((slot, i) => (
                    <FormControlLabel
                      key={i}
                      value={slot}
                      control={<Radio />}
                      label={new Date(slot).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        // timeZone: "America/Chicago",
                      })}
                    />
                  ))}
                </RadioGroup>
                {error && (
                  <FormHelperText error>
                    Please Select one time slot.
                  </FormHelperText>
                )}
              </Box>
              <Box gap={1} display={"flex"} justifyContent={"flex-end"}>
                <Button onClick={() => requestNew()} variant="contained">
                  Request new slot
                </Button>
                <Button
                  // type='submit'
                  onClick={() => confirm()}
                  color="success"
                  variant="contained"
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {!visitDateConfirmed && !scheduleRequested && slots?.length === 1 && (
          <Box sx={cardStyle}>
            <Typography variant="h4">Confirm Tour Schedule</Typography>
            <br />
            <Box
              sx={{
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
                maxWidth: "50rem",
              }}
            >
              <Typography variant="body1">
                Center Supervisor has{" "}
                <strong style={{ textDecoration: "underline" }}>
                  {new Date(slots[0]).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    // timeZone: "America/Chicago",
                  })}
                </strong>{" "}
                available for a tour. Please press confirm to schedule the
                Radiant Montessori tour date.
              </Typography>
              <Box gap={1} display={"flex"} justifyContent={"flex-end"}>
                <Button onClick={() => requestNew()} variant="contained">
                  Request new slot
                </Button>
                <Button
                  // type='submit'
                  onClick={() => confirm()}
                  color="success"
                  variant="contained"
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {visitDateConfirmed && scheduleRequested && !slots && (
          <Box sx={cardStyle}>XXXX</Box>
        )}

        {visitDateConfirmed && !scheduleRequested && (
          <Box sx={cardStyle}>
            <Typography variant="h4">Tour Date Confirmed</Typography>
            <br />
            <Typography variant="body1">
              Thank you for confirming your tour to visit{" "}
              <strong>Radiant Montessori</strong>. Your tour is confirmed on{" "}
              <strong style={{ textDecoration: "underline" }}>
                {new Date(fixedVisitDate).toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  // timeZone: "America/Chicago",
                })}
              </strong>{" "}
              at{" "}
              <strong>
                {new Date(fixedVisitDate).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  // timeZone: "America/Chicago",
                })}
              </strong>
              .
            </Typography>
          </Box>
        )}

        {scheduleRequested && !visitDateConfirmed && (
          <Box sx={cardStyle}>
            <Typography variant="h4">Request Success</Typography>
            <br />
            <Box
              sx={{
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
                maxWidth: "50rem",
              }}
            >
              <Typography variant="body1">
                Your request has been recorded and we'll get back to you with a
                new time slot for the tour.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
};

export default TourConfirmPage;
