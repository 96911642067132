import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';

export const createEmployee = async (employeeData) => {
    const result = await trackPromise(axios.post(`/employees`, employeeData));
    const data = result.data;
    if (data.isSuccess)
        return data.payload;
};
export const getAllEmployees = async () => {
    const result = await trackPromise(axios.get('/employees'));
    const data = result.data;
    if (data.isSuccess) {
        return data.payload;
    }
};
export const getEmployeeById = async (id) => {
    const result = await trackPromise(axios.get(`/employees/${id}`));
    const data = result.data;
    if (data.isSuccess) {
        return data.payload;
    }
};
export const updateEmployeeById = async ({ id, employeeData }) => {
    const result = await trackPromise(axios.put(`/employees/${id}`, employeeData));
    const data = result.data;
    if (data.isSuccess) {
        return data.payload;
    }
};
export const deleteEmployeeById = async (id) => {
    const result = await trackPromise(axios.delete(`/employees/${id}`));
    const data = result.data;
    if (data.isSuccess) {
        return data.payload;
    }
};

const EmployeesService = {
    getAllEmployees,
    getEmployeeById,
    updateEmployeeById,
    deleteEmployeeById,
    createEmployee
};

export default EmployeesService; 