import * as React from "react";
import useTitle from "../../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import LockResetIcon from "@mui/icons-material/LockReset";
import { authService } from "../../services/auth.service";

const theme = createTheme();

const ForgotPasswordPage = (props) => {
  useTitle("Forgot Password");
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get("email");
    if (!email) {
      setError("Email is required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setError("Invalid email address");
    } else {
      setError(null);
      authService.forgotPassword(email).then((s) => {
        if (s.isSuccess) {
          navigate("/login");
        } else {
          setError(s.message);
        }
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component='main'>
        <CssBaseline />
        <Box
          sx={{
            maxWidth: "35rem",
            margin: " 6rem auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "50px",
            background: "#e0e0e0",
            boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
            padding: "5rem 2rem",
          }}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockResetIcon />
          </Avatar>
          <Typography
            component='h1'
            variant='h5'>
            Forgot your password?
          </Typography>
          <Typography variant='body2'>
            Please enter the email address associated with your account and
            we'll send you a link to reset your password.
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
            />

            {error !== null && (
              <Typography
                variant='caption'
                color='red'>
                {error}
              </Typography>
            )}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}>
              Request Reset Link
            </Button>
            <Button
              onClick={() => navigate("/login")}
              fullWidth
              variant='text'
              sx={{ mt: 3, mb: 2 }}>
              Go Back to Login
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default ForgotPasswordPage;
