import { createSlice } from '@reduxjs/toolkit';

export const layoutSlice = createSlice({
    name: 'layout',
    initialState: {
        isSideBarOpen: true,
        snackbarData: {
            isOpen: false,
            message: "",
            severity: "success"
        }
    },
    reducers: {
        toggleSideBar: state => {
            state.isSideBarOpen = !state.isSideBarOpen;
        },
        openSideBar: state => {
            state.isSideBarOpen = true;
        },
        closeSideBar: state => {
            state.isSideBarOpen = false;
        },
        openSnackbar: (state, data) => {
            state.snackbarData = {
                isOpen: data.payload.isOpen,
                message: data.payload.message,
                severity: data.payload.severity
            };
        },
        closeSnackbar: state => {
            state.snackbarData = {
                isOpen: false,
                message: "",
                severity: "success"
            };
        }
    }
});

// Action creators are generated for each case reducer function
export const { toggleSideBar, openSideBar, closeSideBar, openSnackbar, closeSnackbar } = layoutSlice.actions;

//Selectors
export const getSideBarStatus = (state) => state.layout.isSideBarOpen;
export const getSnackbarData = (state) => state.layout.snackbarData;

export default layoutSlice.reducer;