// import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthToken, userLogin } from "./AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useTitle from "../../hooks/useTitle";
import logo from "../../assets/images/logo.svg";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://www.radiant-montessori.com/">
        Radiant Montessori
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();
const LoginPage = () => {
  useTitle("Login");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const authToken = useSelector(getAuthToken);
  const from = location.state?.from || "/dashboard";
  // from ="/dashboard";

  useEffect(() => {
  if (!!authToken) {
    navigate(from);
  }
}, [authToken]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get("email");
    let password = data.get("password");

    if (!email) {
      setError("Email is required");
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setError("Invalid email address");
      return;
    } else if (!password) {
      setError("Password is required");
      return;
    }
    dispatch(userLogin({ email, password })).then((s) => {
      if (!!s?.payload?.userInfo) navigate(from);
      setError("Invalid email or password");
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              maxWidth: "35rem",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "50px",
              background: "#e0e0e0",
              boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
              padding: "2rem 2rem 3rem",
            }}
          >
            <img src={logo} alt="radian-logo" width="150"></img>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 style={{ color: "#00378b" }}>
                Radiant <em>e</em>-Docs
              </h1>
            </div>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
              control={
                <Checkbox
                  value='remember'
                  color='primary'
                />
              }
              label='Remember me'
            /> */}
              {error !== null && (
                <Typography variant="caption" color="red">
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 4, mb: 4 }} />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
