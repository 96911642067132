
export const DocumentStatusMap = new Map([
    [100, { variant: "", label: "Pending", color: "warning" }],
    [200, { variant: "", label: "Viewed", color: "primary" }],
    // [250, { variant: "", label: "Inprogress", color: "primary" }],
    [300, { variant: "", label: "Completed", color: "success" }],
    [400, { variant: "", label: "Rejected", color: "error" }],
    [500, { variant: "outlined", label: "Resend", color: "primary" }],
    [600, { variant: "outlined", label: "Corrected", color: "warning" }],
    [700, { variant: "outlined", label: "Expired", color: "error" }],
]);