import React from "react";
import PropTypes from "prop-types";
import ModalContent from "../../components/Modal/ModalContent";
import { Button } from "@mui/material";
import TemplateForm from "./TemplateForm";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedTemplate, updateTemplate } from "./FormTemplateSlice";

const EditFormTemplateModal = (props) => {
  const formRef = React.useRef(null);
  const dispatch = useDispatch();
  const template = useSelector(getSelectedTemplate);

  const onClickUpdateTemplate = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const handleSubmit = async (templateData) => {
    dispatch(
      updateTemplate({
        ...templateData,
        id: template.id,
        zohoTemplateId: template.zohoTemplateId,
      })
    );
  };

  const content = (
    <TemplateForm
      onSubmit={handleSubmit}
      formRef={formRef}
      defaultValue={template}
      isEdit={true}
    />
  );

  const actions = (
    <React.Fragment>
      <Button onClick={onClickUpdateTemplate} variant="contained">
        Update Template
      </Button>
    </React.Fragment>
  );

  return (
    <ModalContent
      title={"Edit Template"}
      content={content}
      action={actions}
      handleClose={props.modalCloseHandle}
    />
  );
};

EditFormTemplateModal.propTypes = {
  modalCloseHandle: PropTypes.func.isRequired,
};

export default React.forwardRef((props, ref) => (
  <EditFormTemplateModal {...props} forwardedRef={ref} />
));
